import { Autocomplete, Breadcrumbs, Button, List, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Paths from "../Paths";
import { ScenarioNameDialog } from "../components/scenario/ScenarioNameDialog";
import { ScenarioData, useScenarios } from "../api/scenarioServices";
import { ScenarioListItem } from "../components/scenario/ScenarioListItem";

const Scenario = () => {
    const [value, setValue] = useState(null);
    const [scenarioList, setScenarioList] = useState<Array<ScenarioData> | []>([]);
    const [openScenarioCreate, setOpenScenarioCreate] = useState(false);
    const [scenarios, fetchScenarios] = useScenarios();

    const handleClose = () => {
        setOpenScenarioCreate(false);
    };
    useEffect(() => {
        fetchScenarios();
    }, [fetchScenarios]);

    useEffect(() => {
        if (value) {
            setScenarioList([value]);
        } else {
            setScenarioList(scenarios);
        }
    }, [scenarios, value]);

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={Paths.implan.path}>
                    Admin
                </Link>
                <Typography color="text.primary">Scenarios</Typography>
            </Breadcrumbs>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Autocomplete
                    sx={{ minWidth: "75%" }}
                    options={scenarioList}
                    getOptionLabel={(option: any) => option.name}
                    id="controlled-demo"
                    value={value}
                    onChange={(event: any, newValue: any) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Search scenario" variant="standard" />}
                />
                <Tooltip title=" Create New Scenario" followCursor>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setOpenScenarioCreate(true);
                        }}
                    >
                        Create New Scenario
                    </Button>
                </Tooltip>
            </Stack>
            <List>
                {scenarioList.map((scenario: ScenarioData, i: number) => {
                    return <ScenarioListItem key={i} scenario={scenario} />;
                })}
            </List>
            <ScenarioNameDialog openScenarioCreate={openScenarioCreate} handleClose={handleClose} />
        </>
    );
};
export default Scenario;
