import { Alert, Breadcrumbs, Button, List, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Paths from "../Paths";
import { MuiListItem } from "../components/MuiListItem";
import { ImplanFormData, createImplanDataSet, startPreprocess, updateImplanDataSet } from "../api/implanServices";
import ImplanContext from "../context/ImplanContext";
import useAlert from "../hooks/useAlert";

export const ManageImplanData = () => {
    let { id } = useParams();
    const location = useLocation();
    let navigate = useNavigate();

    const { setAlert } = useAlert();

    const [formData, setFormData] = useState<ImplanFormData | null>(null);
    const [implanDataNameSaved, setImplanDataNameSaved] = useState(false);
    const [masterValidated, setMasterValidated] = useState(false);

    const isPathCreate = location.pathname.includes("create");

    const IMPLANDATASETFILES = [
        { id: 1, type: "masterdata", name: "Master data" },
        { id: 2, type: "MULTIPLIER_DATA", name: "Multiplier data" },
        { id: 3, type: "ABSORPTION_DATA", name: "Absorption data" },
        { id: 4, type: "TRADE_DATA", name: "Trade data" },
        { id: 5, type: "COMMODITY_DEMAND_DATA", name: "Commodity demand data" },
        { id: 6, type: "MARKET_SHARE_DATA", name: "Market share data" },
        { id: 7, type: "IMR_DATA", name: "IMR data" },
        { id: 8, type: "STATE_DISTRICT_POPULATION_DATA", name: "State district population" },
        { id: 9, type: "S_INPUT_UPSTREAM", name: "S-Input Upstream" },
        { id: 10, type: "S_INPUT_DOWNSTREAM", name: "S-Input Downstream" },
        { id: 11, type: "INPUT_DIRECT", name: "Input Direct" },
        { id: 12, type: "COMMUNITY_GIVING", name: "Community Giving" },
        { id: 13, type: "BOTTLER_STATES", name: "Bottler States" },
    ];

    const { setImplanId, implanDataSetC } = useContext(ImplanContext);

    useEffect(() => {
        if (id) {
            setImplanId(id);
        }
    }, [id, setImplanId]);

    useEffect(() => {
        if (implanDataSetC) {
            (implanDataSetC.masterDataSetId !== undefined || implanDataSetC.masterDataSetId !== null) && setMasterValidated(true);
        }
    }, [implanDataSetC]);

    const handleImplanDataNameSave = () => {
        setImplanDataNameSaved(true);
        if (formData && formData.name) {
            const name = { name: formData.name };
            createImplanDataSet(name).then((newData: any) => navigate(`${Paths.implan.path}/${newData.id}`));
        }
    };
    const handleImplanDataNameUpdate = (id: string) => {
        if (formData && formData.name) {
            const name = { name: formData.name };
            updateImplanDataSet(name, id).then((res) => setAlert("ImplanDataSet name successfully updated", "success"));
        }
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={Paths.implan.path}>
                    Admin
                </Link>
                <Link color="inherit" to={Paths.implan.path}>
                    Implan
                </Link>
                {isPathCreate ? (
                    <Typography color="text.primary">Create </Typography>
                ) : (
                    <Typography color="text.primary">{implanDataSetC && implanDataSetC.name}</Typography>
                )}
            </Breadcrumbs>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
                {!isPathCreate && implanDataSetC && (
                    <TextField
                        id="implandataname"
                        label="Implan Data Set Name"
                        size="small"
                        defaultValue={implanDataSetC.name}
                        fullWidth
                        onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value });
                            setImplanDataNameSaved(false);
                        }}
                    />
                )}
                {isPathCreate && (
                    <TextField
                        id="implandataname"
                        label="Implan Data Set Name"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value });
                            setImplanDataNameSaved(false);
                        }}
                    />
                )}
                <Button
                    variant="outlined"
                    sx={{ ml: 2 }}
                    onClick={() => {
                        if (isPathCreate) {
                            handleImplanDataNameSave();
                        } else {
                            if (id) {
                                handleImplanDataNameUpdate(id);
                            }
                        }
                    }}
                >
                    {isPathCreate ? "Save" : "Update"}
                </Button>
            </Stack>
            {isPathCreate && !implanDataNameSaved && <Alert severity="info">To upload a file first add a dataset name and save</Alert>}
            <List sx={{ mb: "10rem" }}>
                {implanDataSetC &&
                    IMPLANDATASETFILES.map((implanData: any, i: number) => {
                        return (
                            <MuiListItem
                                key={i}
                                list={implanData}
                                setFormData={setFormData}
                                masterValidated={masterValidated}
                                setMasterValidated={setMasterValidated}
                            />
                        );
                    })}
                <Stack direction="row" alignItems="center" justifyContent="space-between" my={4}>
                    <Button
                        variant="outlined"
                        onClick={() =>
                            implanDataSetC &&
                            startPreprocess(implanDataSetC.id)
                                .then((data) => {
                                    data.error && setAlert(data.error, "error");
                                })
                                .catch((err) => console.log(err))
                        }
                    >
                        Start Preprocess
                    </Button>{" "}
                    <Typography fontWeight={500}>Dataset Status:{implanDataSetC?.state}</Typography>
                </Stack>
            </List>
        </>
    );
};
