import { Box, Stack, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { millionsToString, thousandsToString } from "../utils/numberDisplay";
import { getConvertedData } from "./PieChart";
import { communityGivingPieChartColors, getForegroundColor } from "./colors";

type Props = {
    data: Array<{ label: string; value: number }>;
};

export const PieChartPDF = ({ data }: Props) => {
    const chartData = getConvertedData(data);
    const total = data.reduce((total, item) => item.value + total, 0);
    const chartTheme = {
        fontFamily: "Inter, Arial, sans-serif",
        labels: {
            text: {
                fontSize: 14,
                fontWeight: 500,
            },
        },
    };

    const groupSize = Math.ceil(chartData.legends.length / 4);
    const groupedLegends: Array<Array<{ index: number; legend: string }>> = [];
    for (let i = 0; i < chartData.legends.length; ++i) {
        if (i % groupSize === 0) {
            groupedLegends.push([]);
        }
        groupedLegends[groupedLegends.length - 1].push({ index: i, legend: chartData.legends[i] });
    }

    return (
        <Box height="100%" width="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center" pt={4} pb={5}>
            {/* Check if there is data to show pie chart */}
            {!chartData.data.every((c) => c.value === 0) ? (
                <ResponsivePie
                    // show only non-zero values in chart
                    data={chartData.data}
                    margin={{ top: 68, right: 120, bottom: 22, left: 100 }} //Dont change bottom margin less then 22 to avoid out of border
                    sortByValue={true}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    theme={chartTheme}
                    activeOuterRadiusOffset={8}
                    arcLabel={(e) => ((e.value / total) * 100).toFixed(1) + "%"}
                    arcLinkLabel={(e) => (e.value > 100000 ? "$" + millionsToString(e.value) + "M" : "$" + thousandsToString(e.value) + "K")}
                    borderWidth={1}
                    colors={communityGivingPieChartColors}
                    tooltip={(point) => {
                        return (
                            <Box
                                sx={{
                                    color: "#333333",
                                    pt: 1,
                                    pb: 1,
                                    backgroundColor: "white",
                                    border: "solid 1px #DDD",
                                }}
                            >
                                <Typography ml={1} variant="caption">
                                    {point.datum.label}:
                                </Typography>
                                <Typography mr={1} variant="caption">
                                    ${millionsToString(point.datum.value)} Million
                                </Typography>
                            </Box>
                        );
                    }}
                    enableArcLinkLabels={true}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={18}
                    arcLabelsTextColor={getForegroundColor}
                />
            ) : (
                <Typography py={15}>No Available Data</Typography>
            )}
            <Box width="100%" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-around" flexWrap="wrap" mb={4}>
                {groupedLegends.map((group, i) => (
                    <Box key={i}>
                        {group.map((k, i) => (
                            <Stack key={i} direction="row" width="100%" my={2}>
                                <Box width="12px" height="12px" sx={{ backgroundColor: communityGivingPieChartColors[k.index], mr: "0.3rem" }} />
                                <Typography mr={2} variant="caption">
                                    {k.legend}
                                </Typography>
                            </Stack>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
