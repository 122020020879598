export const sortObjectsById = (objects: Array<{ id: number; name: string }>) => {
    const sortedObjects = objects.sort((a, b) => a.id - b.id);
    return sortedObjects;
};
export const sortObjectsByName = (objects: Array<{ id: number; name: string }>) => {
    const sortedObjects = objects.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        return nameA.localeCompare(nameB, undefined, { numeric: true });
    });

    return sortedObjects;
};
