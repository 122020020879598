import { Box, Link, Paper, Typography } from "@mui/material";
export const Footer = () => {
    return (
        <Paper>
            <Box id="footerImg" sx={{ backgroundColor: "#F40009", p: 3, color: "white", display: "flex", alignItems: "center", height: "120px" }}>
                <Box sx={{ ml: 2, position: "absolute" }}>
                    <Link sx={{ color: "white", display: "block" }} href="https://www.stewardredqueen.com/about/">
                        About
                    </Link>
                    <Link sx={{ color: "white", display: "block" }} href="https://www.stewardredqueen.com/contact/">
                        Contact
                    </Link>
                    <Link sx={{ color: "white", display: "block" }} href="/glossary">
                        Glossary
                    </Link>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
                    <Typography variant="caption" component="div" color="white">
                        Powered by{" "}
                        <Typography variant="caption" component="span" fontWeight="bold">
                            Steward Redqueen
                        </Typography>
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};
