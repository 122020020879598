import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { useState } from "react";
import { BottlerForm } from "./Bottlers";
import { useAddBottler } from "../api/userServices";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CreateBottlerForm(props: any) {
    const { isOpen, setIsOpen } = props;
    const [form, setForm] = useState<BottlerForm>({});
    const addBottler = useAddBottler({ errorMessage: "Error creating bottler", successMessage: "Bottler successfully created." });

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Add New Bottler
            </BootstrapDialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", minWidth: "500px" }} dividers>
                <TextField
                    id="standard-size-small"
                    value={form.name}
                    label="Name"
                    size="small"
                    variant="standard"
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => {
                        addBottler(form);
                        handleClose();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
