export const calculateValueAddedPercentage = (number: string) => {
    const valueAddedPercentage = parseFloat(number);
    if (valueAddedPercentage < 1) {
        return valueAddedPercentage.toFixed(2).replace(/^0\./, ".");
    } else if (valueAddedPercentage < 10) {
        return valueAddedPercentage.toFixed(1);
    } else {
        return Math.floor(valueAddedPercentage).toString();
    }
};
