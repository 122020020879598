import Header from "../components/Header";
import { Footer } from "../components/Footer";
import { Box, Link, Typography, TypographyProps } from "@mui/material";

const GlossaryParagraph = (props: TypographyProps) => <Typography variant="caption" component="div" lineHeight={1.5} mt={3} {...props} />;
const GlossarySpan = (props: TypographyProps) => <Typography variant="caption" component="span" fontWeight="bold" {...props} />;
export default function Glossary() {
    return (
        <Box display="flex" flexDirection="column" minHeight="100vh" width="100%">
            <Header />
            <Box flex="1" paddingX={{ lg: 20, md: 3 }}>
                <Box mt={9} mb={8} p={4}>
                    <Typography variant="h6" textAlign="center" component="div">
                        Glossary of Terms for Coca Cola’s Economic Impact Study Dashboard
                    </Typography>
                    <GlossaryParagraph>
                        <GlossarySpan>Multi-regional input-output (MRIO) modelling: </GlossarySpan>
                        The methodology used to calculate the indirect and induced impacts displayed in the dashboard. In the MRIO model, spending by the
                        Coca-Cola system is used to estimate the total economic output in a state related to the system. The model also uses inter-state trade
                        flow data for 530 commodities to estimate the amount of output generated by sectors in other states necessary to meet that initial
                        demand in the origin state. This is repeated for many iterations across 546 sectors and 50 states and DC. More information on MRIO
                        models, methodology, and the data used is available on the external dashboard’s{" "}
                        <Link
                            sx={{ color: "blue", textDecorationColor: "blue" }}
                            href="https://www.coca-colacompany.com/content/dam/company/us/en/economic-impact-map/US%20Impact%20Study%20Methodology_092023.pdf"
                        >
                            methodology page
                        </Link>{" "}
                        and on the IMPLAN{" "}
                        <Link
                            sx={{ color: "blue", textDecorationColor: "blue" }}
                            href="https://support.implan.com/hc/en-us/articles/115009713448-MRIO-Introduction-to-Multi-Regional-Input-Output-Analysis"
                        >
                            website
                        </Link>
                        .
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Direct jobs: </GlossarySpan> In this context, jobs created by the Coca-Cola system (The Coca-Cola Company and its
                        independent bottling partners).
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Indirect jobs: </GlossarySpan>Jobs supported in the value chain through the production and sale of Coca-Cola products (ex.
                        Someone who works at a factory that produces aluminum cans).
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Induced jobs: </GlossarySpan> Jobs supported by the personal spending of both direct and indirect workers.
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Trade jobs: </GlossarySpan> Positions at retail stores such as convenience stores, supermarkets, gas stations, and any
                        other retailers that sell Coca-Cola products.
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Service jobs: </GlossarySpan> Positions at bars, restaurants, hotels, etc.
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Value added: </GlossarySpan> The sum of salaries, taxes, and profits, contributing to the country’s Gross Domestic Product
                        (GDP).
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Gross Domestic Product (GDP): </GlossarySpan> A monetary measure of the value of final goods and services produced in the
                        economy; it is a comprehensive measure of economic activity for a country.
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Upstream: </GlossarySpan>The upstream side of the value chain refers to the goods and services the Coca-Cola system
                        procures in order to make the product. This includes their suppliers and their suppliers’ suppliers. For example, a bottler’s upstream
                        impacts include the money it spent on procuring aluminum cans, as well as the money the can producer spent on the raw material used to
                        produce those cans.
                    </GlossaryParagraph>
                    <GlossaryParagraph>
                        <GlossarySpan>Downstream: </GlossarySpan>The downstream side of the value chain refers to the sale of the product. This includes trade
                        outlets, such as restaurants and stores, and trade partners’ suppliers, such as a restaurant procuring cups or chairs.
                    </GlossaryParagraph>
                </Box>
            </Box>
            <Box style={{ position: "sticky", bottom: 0, width: "100%", zIndex: 1000 }}>
                <Footer />
            </Box>
        </Box>
    );
}
