import { AppBar, IconButton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import headerLogo from "../assets/headerLogo.png";
import * as Paths from "../Paths";
import { useContext } from "react";
import { UserInfoContext } from "../context/UserInfoContext";

type HeaderProps = {
    offset?: number;
    handleIconClick?: () => void;
    title?: string;
};

function Header(props: HeaderProps) {
    const offset = props.offset ? props.offset : 0;
    const userInfo = useContext(UserInfoContext);

    return (
        <AppBar
            component="nav"
            sx={{
                width: { md: `calc(100% - ${offset}px)` },
                m1: { md: `${offset}px` },
            }}
        >
            <Toolbar sx={{ flexDirection: "row", backgroundColor: "#F40009" }} id="headerImg">
                <IconButton color="inherit" aria-label="Open menu" edge="start" onClick={props.handleIconClick} sx={{ mr: 2, display: { md: "none" } }}>
                    <MenuIcon />
                </IconButton>

                <Stack ml={2} direction="row" alignItems="center" pb={0.5}>
                    <img src={headerLogo} alt="Coca-Cola logo" height={35} />
                </Stack>

                <Typography sx={{ flexGrow: 1 }} variant="h6">
                    {props.title}
                </Typography>

                <Tooltip title="Home">
                    <IconButton component={Link} to={Paths.dashboard.path} sx={{ color: "white" }}>
                        <HomeIcon />
                    </IconButton>
                </Tooltip>
                {userInfo && userInfo.userRole === "Admin" && (
                    <Tooltip title="Admin">
                        <IconButton component={Link} to={Paths.implan.path} sx={{ color: "white" }}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Logout">
                    <IconButton
                        onClick={() => {
                            document.location.href = Paths.logout.path;
                        }}
                        sx={{ color: "white" }}
                    >
                        <LogoutIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
