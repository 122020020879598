import { Box, Button, Stack, Tooltip } from "@mui/material";
import ScenarioSelector from "./ScenarioSelector";

import StatesSelector from "./StatesSelector";
import DistrictsSelector from "./DistrictsSelector";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import { useContext } from "react";
import DashboardContext from "../context/DashboardContext";
import { useMediaQuery } from "@mui/material";

export type District = {
    id: number;
    name: string;
};

export const DashboardSelectGroup = () => {
    const { scenarioId, scenarios, getActiveStates, states, allSelected, selectState } = useContext(DashboardContext);
    const isLargeScreen = useMediaQuery("(min-width: 1400px)");
    const isMediumScreen = useMediaQuery("(min-width: 900px)");
    return (
        <Stack mt={3} mb={3} direction="row" justifyContent="center" flexWrap="wrap" width="100%">
            <Stack direction="row" width={isLargeScreen || isMediumScreen ? "55%" : "100%"} flexWrap="wrap">
                {scenarios && (
                    <Box width={isLargeScreen ? "35%" : "100%"}>
                        <ScenarioSelector />
                    </Box>
                )}
                <Stack direction="row" justifyContent="space-between" width={isLargeScreen ? "40%" : "100%"}>
                    <Box width={isLargeScreen ? "80%" : "100%"}>
                        <StatesSelector />
                    </Box>
                    {!allSelected && (
                        <Tooltip title="Select All States" arrow>
                            <Box width="70px">
                                <Button
                                    sx={{ py: 0.85 }}
                                    disabled={!scenarioId}
                                    variant="outlined"
                                    onClick={() => {
                                        selectState(null);
                                    }}
                                >
                                    <ChecklistRtlIcon />
                                </Button>
                            </Box>
                        </Tooltip>
                    )}
                    {allSelected && (
                        <Tooltip title="Unselect States" arrow>
                            <Box width="70px">
                                <Button
                                    sx={{ p: 0.85 }}
                                    disabled={!scenarioId}
                                    variant="outlined"
                                    onClick={() => {
                                        const activeStates = getActiveStates();
                                        selectState(activeStates ? activeStates[0] : states[0]);
                                    }}
                                >
                                    <RemoveDoneIcon
                                        onClick={() => {
                                            const activeStates = getActiveStates();
                                            selectState(activeStates ? activeStates[0] : states[0]);
                                        }}
                                    />
                                </Button>
                            </Box>
                        </Tooltip>
                    )}
                </Stack>

                <Box width={isLargeScreen ? "25%" : "100%"}>
                    <DistrictsSelector />
                </Box>
            </Stack>
        </Stack>
    );
};
