import { Box, Divider, Stack, Typography } from "@mui/material";
import { HorizontalBarChart } from "../charts/HorizontalBarChart";
import { EmploymentByValue } from "./EmploymentByValue";
import { employmentChartBySectorColors } from "../charts/colors";
import { cardSettings } from "../widgets/constants";
import { EmploymentType } from "../types/dashboard";
import { chartTitleStyling, getChartBoxStyling } from "../charts/styling";

type Props = {
    employmentChartData: EmploymentType;
    animate?: boolean;
    height: number;
};
export const Employment = ({ employmentChartData, animate, height }: Props) => {
    const convertedBySector: Array<{}> = employmentChartData.bySector.map((s) => {
        return {
            type: s.sector,
            "Direct suppliers and outlets": s.direct,
            "Suppliers' suppliers and trade outlets' suppliers": s.indirect,
            Induced: s.induced,
            total: s.total,
        };
    });

    convertedBySector.push({
        type: "The Coca-Cola system",
        "The Coca-Cola system": employmentChartData.system.headcount,
        total: employmentChartData.system.headcount,
    }); // this is expected

    return (
        <Stack
            direction={cardSettings.direction}
            justifyContent={cardSettings.justifyContent}
            flexWrap={cardSettings.flexWrap}
            border={cardSettings.border}
            boxShadow={cardSettings.boxShadow}
            borderRadius={cardSettings.borderRadius}
            pt={5}
            pb={5}
            mt={25}
        >
            <Typography variant="h5" color="primary" fontWeight={600} mt={2} mb={3}>
                Employment
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Box sx={getChartBoxStyling(height)}>
                    <Typography component="span" sx={chartTitleStyling}>
                        Employment supported by level <span>(# of people)</span>
                    </Typography>
                    <EmploymentByValue {...{ employmentChartData, animate }} />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mt: 6 }} />
                <Box sx={getChartBoxStyling(height)}>
                    <Typography component="span" sx={chartTitleStyling}>
                        Employment supported by sector <span>(# of people)</span>
                    </Typography>
                    <HorizontalBarChart
                        data={convertedBySector} //by sector
                        indexBy="type"
                        keys={["Direct suppliers and outlets", "Suppliers' suppliers and trade outlets' suppliers", "Induced", "The Coca-Cola system"]}
                        colors={employmentChartBySectorColors}
                        isSystemCleared={true}
                        title="Employment supported by sector"
                        boldSpan={`(# of people) `}
                        animate={animate}
                    />
                </Box>
            </Stack>
        </Stack>
    );
};
