import FormControl from "@mui/material/FormControl";
import { Autocomplete, TextField } from "@mui/material";
import { useContext } from "react";
import DashboardContext from "../context/DashboardContext";

type State = {
    id: number;
    name: string;
    active: boolean;
};
export default function StatesSelector() {
    const { states, state, selectState, getActiveStates } = useContext(DashboardContext);
    const activeStates = getActiveStates();
    const isOptionDisabled = (option: State) => {
        // Disable states according to active status
        return !!activeStates && !activeStates.map((s) => s.name).includes(option.name);
    };
    return (
        <FormControl fullWidth>
            <Autocomplete
                options={states}
                getOptionLabel={(option: State | null) => (option ? option.name : "")}
                getOptionDisabled={isOptionDisabled}
                size="small"
                id="stateoption"
                value={state ? state : null}
                onChange={(event: any, newValue: State | null) => {
                    selectState(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="State" />}
            />
        </FormControl>
    );
}
