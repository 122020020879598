import { useCallback, useContext, useState } from "react";
import { LoginContext } from "../auth/CheckAuth";
import useAlert from "./useAlert";

type Fetch = {
    isLoading: boolean;
    error: boolean;
    get: () => void;
    post: (data?: any) => void;
    put: (data?: any) => void;
    del: () => void;
};

export type FetchOptions = {
    errorMessage?: string;
    successMessage?: string;
};

export function useFetch(url: string, onReceived?: (value: any) => void, options?: FetchOptions): Fetch {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onNotLoggedIn = useContext(LoginContext);
    const { setAlert } = useAlert();

    const setErrorAlert = useCallback(() => {
        if (options?.errorMessage) {
            setAlert(options.errorMessage, "error");
        }
    }, [options?.errorMessage, setAlert]);

    const setSuccesAlert = useCallback(() => {
        if (options?.successMessage) {
            setAlert(options.successMessage, "success");
        }
    }, [options?.successMessage, setAlert]);

    const get = useCallback(() => {
        setError(false);
        setIsLoading(true);

        const callFetch = async () => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    setErrorAlert();
                    setError(true);
                } else {
                    const data = await response.json();
                    setSuccesAlert();
                    onReceived && onReceived(data);
                }
                setIsLoading(false);
            } catch (e) {
                onNotLoggedIn();
            }
        };

        try {
            callFetch();
        } catch (error) {
            console.error(error);
            setError(true);
            setIsLoading(false);
        }
    }, [url, setErrorAlert, setSuccesAlert, onReceived, onNotLoggedIn]);

    const post = useCallback(
        (postData: any) => {
            setError(false);
            setIsLoading(true);

            const options: RequestInit = {
                method: "POST",
                redirect: "error",
            };
            if (postData !== undefined) {
                options.body = JSON.stringify(postData);
                options.headers = {
                    "Content-Type": "application/json",
                };
            }

            const callFetch = async () => {
                try {
                    const response = await fetch(url, options);
                    if (!response.ok) {
                        setErrorAlert();
                        setError(true);
                    } else {
                        const data = await response.json();
                        setSuccesAlert();
                        onReceived && onReceived(data);
                    }
                    setIsLoading(false);
                } catch (e) {
                    onNotLoggedIn();
                }
            };
            try {
                callFetch();
            } catch (error) {
                console.error(error);
                setError(true);
                setIsLoading(false);
            }
        },
        [url, setErrorAlert, setSuccesAlert, onReceived, onNotLoggedIn]
    );

    const put = useCallback(
        (postData?: any) => {
            setError(false);
            setIsLoading(true);

            const options: RequestInit = {
                method: "PUT",
                redirect: "error",
            };
            if (postData !== undefined) {
                options.body = JSON.stringify(postData);
                options.headers = {
                    "Content-Type": "application/json",
                };
            }

            const callFetch = async () => {
                try {
                    const response = await fetch(url, options);
                    if (!response.ok) {
                        setErrorAlert();
                        setError(true);
                    } else {
                        setSuccesAlert();
                        if (onReceived) {
                            const data = response.json();
                            onReceived(data);
                        }
                    }
                    setIsLoading(false);
                } catch (e) {
                    console.error(e);
                    onNotLoggedIn();
                }
            };
            try {
                callFetch();
            } catch (error) {
                console.error(error);
                setError(true);
                setIsLoading(false);
            }
        },
        [url, setErrorAlert, setSuccesAlert, onReceived, onNotLoggedIn]
    );

    const del = useCallback(() => {
        setError(false);
        setIsLoading(true);

        const callFetch = async () => {
            try {
                const response = await fetch(url, {
                    method: "DELETE",
                    redirect: "error",
                });
                if (!response.ok) {
                    setErrorAlert();
                    setError(true);
                } else {
                    setSuccesAlert();
                }
                setIsLoading(false);
            } catch (e) {
                onNotLoggedIn();
            }
        };
        try {
            callFetch();
        } catch (error) {
            console.error(error);
            setError(true);
            setIsLoading(false);
        }
    }, [url, setErrorAlert, setSuccesAlert, onNotLoggedIn]);

    return { isLoading, error, get, post, put, del };
}
