import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, TextField } from "@mui/material";
import { useState } from "react";
import { createScenario } from "../../api/scenarioServices";
import useAlert from "../../hooks/useAlert";
import { useNavigate } from "react-router-dom";
import * as Paths from "../../Paths";

type Props = {
    handleClose: () => void;
    openScenarioCreate: boolean;
};
export const ScenarioNameDialog = (props: Props) => {
    const { handleClose, openScenarioCreate } = props;
    const [name, setName] = useState<string>("");
    const { setAlert } = useAlert();
    const navigate = useNavigate();

    return (
        <Dialog onClose={handleClose} open={openScenarioCreate} fullWidth>
            <DialogTitle>Create New Scenario</DialogTitle>
            <Divider />
            <Box m={4}>
                <TextField
                    sx={{ width: "100%" }}
                    id="scenarioName"
                    size="small"
                    name="fileName"
                    label="Add a scenario name"
                    onChange={(e) => setName(e.target.value)}
                />
            </Box>
            <Divider />
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    onClick={() => {
                        createScenario({ name })
                            .then((res: any) => {
                                if (res.id && res.name) {
                                    setAlert("Scenario successfully created.", "success");
                                    navigate(`${Paths.scenarios.path}/${res.id}`);
                                } else {
                                    setAlert(res.error, "error");
                                }
                            })
                            .catch((err) => setAlert(err.error, "error"));
                        handleClose();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
