import { useTheme } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import { BarItem, BarItemProps, ResponsiveBar } from "@nivo/bar";
import { createContext, useContext, useEffect, useState } from "react";
import { defaultNumberFormatter } from "../utils/numberDisplay";
import { getForegroundColor } from "./colors";
import { barChartBoxStyling } from "./styling";

type Props = {
    data: Array<any>;
    keys: Array<string>;
    indexBy: string;
    colors: Array<string>;
    title: string;
    boldSpan: string;
    animate?: boolean;
    numberFormatter?: (value: number | null | undefined) => string;
    isSystemCleared: boolean;
};

const KeysContext = createContext<Array<any>>([]);
const NumberFormatterContext = createContext(defaultNumberFormatter);

const BarWithTotals = (props: BarItemProps<{ [key: string]: number }>) => {
    const theme = useTheme();
    const keys = useContext(KeysContext);
    const numberFormatter = useContext(NumberFormatterContext);

    const dataId = props.bar.data.id;

    const actualKeys = Object.keys(props.bar.data.data).filter((e) => e !== "total" && e !== "type");
    const actualKeysOrdered = keys.filter((k) => actualKeys.indexOf(k) !== -1);
    const lastDataId = actualKeysOrdered[actualKeysOrdered.length - 1];

    if (dataId === lastDataId) {
        // Last key
        const bar = props.bar;

        // Calculate the x-position for the text based on the bar width
        const textX = bar.x + bar.width + 5;
        const textY = bar.y + bar.height / 2;

        const total = bar.data.data.total;

        return (
            <>
                <BarItem {...props} />
                <g transform={`translate(${textX}, ${textY})`}>
                    <text
                        textAnchor="left"
                        dominantBaseline="central"
                        style={{
                            ...theme,
                            pointerEvents: "none",
                        }}
                    >
                        {numberFormatter(total)}
                    </text>
                </g>
            </>
        );
    } else {
        return <BarItem {...props} />;
    }
};

export const HorizontalBarChart = ({ data, keys, indexBy, colors, title, boldSpan, animate, numberFormatter, isSystemCleared }: Props) => {
    const [tickRotation, setTickRotation] = useState(0);
    const breakpoint = 1930; // Change this value to your desired breakpoint
    const startBreakPoint = 908;
    const marginStyling = { top: 50, right: 80, bottom: window.innerWidth < startBreakPoint ? 60 : 40, left: 130 };
    const numberFormatterInternal = numberFormatter ? numberFormatter : defaultNumberFormatter;

    const chartTheme = {
        fontFamily: "Inter, Arial, sans-serif",
        labels: {
            text: {
                fontSize: 14,
                fontWeight: 500,
            },
        },
    };
    useEffect(() => {
        const getRotation = () => {
            // Adjust tick rotation based on breakpoint

            if (window.innerWidth < breakpoint) {
                if (window.innerWidth > startBreakPoint) {
                    return 30;
                } else if (window.innerWidth < startBreakPoint) {
                    return 90;
                } else {
                    return 60;
                }
            }
            return 0;
        };
        const handleResize = () => {
            const newTickRotation = getRotation();
            setTickRotation(newTickRotation);
        };

        // Initial setup
        handleResize();

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <KeysContext.Provider value={keys}>
            <NumberFormatterContext.Provider value={numberFormatterInternal}>
                <Box sx={barChartBoxStyling}>
                    <ResponsiveBar
                        animate={animate}
                        data={data}
                        keys={keys}
                        indexBy={indexBy}
                        margin={marginStyling}
                        padding={0.3}
                        layout="horizontal"
                        innerPadding={1}
                        enableGridY={false}
                        enableGridX={false}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        theme={chartTheme}
                        colors={colors}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: tickRotation,
                            legendPosition: "middle",
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendPosition: "middle",
                        }}
                        labelSkipWidth={80}
                        labelSkipHeight={12}
                        labelTextColor={getForegroundColor}
                        label={(d) => `${isSystemCleared && d.id === "The Coca-Cola System" ? "" : numberFormatterInternal(d.value)}`}
                        valueFormat={(d) => numberFormatterInternal(d)}
                        barComponent={BarWithTotals}
                    />
                    <Stack direction="row" justifyContent="space-around" width="100%">
                        <Stack direction="column" alignItems="flex-start" justifyContent="center" flexWrap="wrap" mt={1}>
                            {keys.map(
                                (k, i) =>
                                    i <= 1 && (
                                        <Stack key={i} direction="row" alignItems="center" justifyContent="center" mt={1.5}>
                                            <Box
                                                width="12px"
                                                height="12px"
                                                sx={{
                                                    backgroundColor: colors[i],
                                                    mr: "0.3rem",
                                                }}
                                            />
                                            <Typography mr={2} variant="caption">
                                                {k}
                                            </Typography>
                                        </Stack>
                                    )
                            )}
                        </Stack>
                        <Stack direction="column" alignItems="flex-start" justifyContent="center" flexWrap="wrap" mt={1}>
                            {keys.map(
                                (k, i) =>
                                    i > 1 && (
                                        <Stack key={i} direction="row" alignItems="center" justifyContent="center" mt={1.5}>
                                            <Box
                                                width="12px"
                                                height="12px"
                                                sx={{
                                                    backgroundColor: colors[i],
                                                    mr: "0.3rem",
                                                }}
                                            />
                                            <Typography mr={2} variant="caption">
                                                {k}
                                            </Typography>
                                        </Stack>
                                    )
                            )}
                        </Stack>
                    </Stack>
                </Box>
            </NumberFormatterContext.Provider>
        </KeysContext.Provider>
    );
};
