import { BarChart } from "../charts/BarChart";
import { byValueChainLinkColors } from "../charts/colors";
import { ValueAddedType } from "../types/dashboard";
import { millionsToString } from "../utils/numberDisplay";

type Props = {
    valueAddedChartData: ValueAddedType;
    animate?: boolean;
    title?: boolean;
};
export const ValueAddedByChainLink = ({ valueAddedChartData, animate, title }: Props) => {
    const byValueChainLink = [
        {
            type: "Upstream",
            "Direct suppliers": valueAddedChartData.byValueChainLink.upstream.direct,
            "Suppliers' suppliers": valueAddedChartData.byValueChainLink.upstream.indirect,
            total: valueAddedChartData.byValueChainLink.upstream.total,
        },
        {
            type: "The Coca-Cola system",
            "The Coca-Cola system": valueAddedChartData.system.total,
            total: valueAddedChartData.system.total,
        },
        {
            type: "Downstream",
            "Direct trade outlets": valueAddedChartData.byValueChainLink.downstream.direct,
            "Trade outlets' suppliers": valueAddedChartData.byValueChainLink.downstream.indirect,
            "Consumer sales tax": valueAddedChartData.byValueChainLink.downstream.salesTax,
            total: valueAddedChartData.byValueChainLink.downstream.total,
        },
    ];

    return (
        <BarChart
            data={byValueChainLink}
            indexBy="type"
            keys={[
                "Direct suppliers",
                "Suppliers' suppliers",
                "The Coca-Cola system",
                "Direct trade outlets",
                "Trade outlets' suppliers",
                "Consumer sales tax",
            ]}
            colors={byValueChainLinkColors}
            title="Value added supported by level"
            boldSpan="(USD million)"
            isSystemCleared={true}
            renderTitle={title}
            animate={animate}
            numberFormatter={millionsToString}
            legendTotalInOneColumn={2}
        />
    );
};
