export const dollarsToString = (dollars: number | string | null | undefined) => {
    if (dollars !== null && dollars !== undefined) {
        if (typeof dollars === "string") {
            dollars = parseFloat(dollars);
        }
        let postfix = "";
        if (dollars >= 1000000000000) {
            postfix = "T";
            dollars = dollars / 1000000000000;
        } else if (dollars >= 1000000000) {
            postfix = "B";
            dollars = dollars / 1000000000;
        } else if (dollars >= 1000000) {
            postfix = "M";
            dollars = dollars / 1000000;
        } else if (dollars >= 1000) {
            postfix = "K";
            dollars = dollars / 1000;
        }
        return "$" + dollars.toFixed(2) + postfix;
    } else {
        return "$0";
    }
};

export const millionsToString = (millions: number | null | undefined) => {
    if (millions === null || millions === undefined) {
        return "";
    } else {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2, maximumSignificantDigits: 3 };
        return (millions / 1000000).toLocaleString("en-US", options);
    }
};

export const thousandsToString = (thousands: number | null | undefined) => {
    if (thousands === null || thousands === undefined) {
        return "";
    } else {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2, maximumSignificantDigits: 3 };
        return (thousands / 1000).toLocaleString("en-US", options);
    }
};

export const defaultNumberFormatter = (number: number | null | undefined) => {
    if (number === null || number === undefined) {
        return "";
    } else {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2, maximumSignificantDigits: 3 };
        return number.toLocaleString("en-US", options);
    }
};
