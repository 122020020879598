import { useContext, useEffect, useState } from "react";
import { useResults } from "../api/dashboardServices";
import "./dashboard.css";

import DashboardContext from "../context/DashboardContext";
import { DashboardBrowser } from "./DashboardBrowser";
import { DashboardPdf } from "./DashboardPdf";

export const Dashboard = () => {
    const [result, setResult, getResults] = useResults({
        successMessage: "Dashboard results successfully updated",
        errorMessage: "Something went wrong while fetching result data",
    });

    const { scenarioId, district, states, state, allSelected } = useContext(DashboardContext);

    useEffect(() => {
        setResult(null);
    }, [scenarioId, state, district, setResult]);

    useEffect(() => {
        if (scenarioId) {
            if (allSelected) {
                getResults(scenarioId, [], []);
            } else if (!allSelected && state) {
                const stateIds = [state.id.toString()];
                const districtIds = district ? [district.id.toString()] : [];

                getResults(scenarioId, stateIds, districtIds);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioId, district, state, allSelected, states]);

    const [pdf, setPdf] = useState(false);

    if (result && pdf) {
        return <DashboardPdf result={result} onPdfDownloaded={() => setPdf(false)} />;
    } else {
        return <DashboardBrowser result={result} createPdf={() => setPdf(true)} />;
    }
};
