import FormControl from "@mui/material/FormControl";

import { Autocomplete, TextField } from "@mui/material";
import { District } from "./DashboardSelectGroup";
import { useContext } from "react";
import DashboardContext from "../context/DashboardContext";

export default function DistrictsSelector() {
    const { state, district, setDistrict, allSelected, districts } = useContext(DashboardContext);
    return (
        <FormControl fullWidth>
            <Autocomplete
                disabled={state === null || allSelected}
                options={districts ? districts : []}
                getOptionLabel={(option: District) => option.name}
                size="small"
                id="controlled-demo"
                value={district ? district : null}
                onChange={(event: any, newValue: District | null) => {
                    setDistrict(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="District" />}
            />
        </FormControl>
    );
}
