import { PersonPinCircleOutlined } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import * as Paths from "../Paths";
import Header from "../components/Header";

const drawerWidth = 240;

interface Props {
    window?: () => Window;
}

export default function AdminPage(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar />
            <List sx={{ a: { textDecoration: "none", color: "black" } }}>
                <Link to={Paths.implan.path}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PersonPinCircleOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Implan" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to={Paths.scenarios.path}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <EventAvailableIcon />
                            </ListItemIcon>
                            <ListItemText primary="Scenarios" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to={Paths.users.path}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PeopleAltIcon />
                            </ListItemIcon>
                            <ListItemText primary="User Management" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to={Paths.bottlers.path}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <HandshakeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Bottlers" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to={Paths.dashboard.path}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AccountTreeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "100%" }}>
            <CssBaseline />
            <Header />
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 1,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: "auto",
                }}
            >
                <Toolbar />
                <Box sx={{ width: "95%", m: "auto" }}>
                    <Outlet />
                </Box>
            </Box>
            <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    component="div"
                    showLabels
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                    }}
                >
                    <BottomNavigationAction sx={{ width: "100%", cursor: "default" }} label="Powered by Steward Redqueen" />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}
