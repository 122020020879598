import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { getAllStateNames } from "../widgets/getAllStateNames";
import { District } from "../components/DashboardSelectGroup";
import { getDistricts, getStates } from "../api/dashboardServices";
import { useSearchParams } from "react-router-dom";
import { ScenarioData, useScenarios } from "../api/scenarioServices";
import { sortObjectsByName } from "../utils/sortObjects";
import { State } from "../types/dashboard";

type DashboardContextType = {
    scenarioId: string | null;
    selectScenarioById: (scenarioId: string | null) => void;
    scenarios: Array<ScenarioData> | [];
    states: Array<State>;
    setStates: (states: Array<State>) => void;
    getDashboardScenarioName: () => ScenarioData | undefined;
    getSelectedState: () => State | undefined;
    state: State | null;
    setState: (d: State | null) => void;
    selectState: (d: State | null) => void;
    district: District | null;
    setDistrict: (d: District | null) => void;
    stateNames: Array<string>;
    setStateNames: (stateNames: Array<string>) => void;
    allSelected: boolean;
    districts: Array<District> | undefined;
    getActiveStates: () => Array<State> | undefined;
};

const DashboardContext = createContext<DashboardContextType>({
    scenarioId: "",
    selectScenarioById: () => {},
    scenarios: [],
    states: [],
    setStates: () => {},
    getDashboardScenarioName: () => undefined,
    getSelectedState: () => undefined,
    state: null,
    setState: () => {},
    selectState: () => {},
    district: null,
    setDistrict: () => {},
    stateNames: [],
    setStateNames: () => {},
    allSelected: false,
    districts: [],
    getActiveStates: () => undefined,
});

export const updateUrl = (scenario: string | null, selectedState: string | null = null, selectedDistrict: string | null = null) => {
    const queryParams = [];
    if (scenario) {
        queryParams.push(`idScenario=${scenario}`);
    }
    if (selectedState) {
        queryParams.push(`idState=${selectedState}`);
    }
    if (selectedDistrict) {
        queryParams.push(`idDistrict=${selectedDistrict}`);
    }
    const queryString = queryParams.join("&");
    window.history.pushState(null, "", `?${queryString}`);
};

export const DashboardProvider = ({ children }: PropsWithChildren) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [scenarioId, setScenarioId] = useState<string | null>(null);

    const [states, setStates] = useState<Array<State>>([]);
    const [stateNames, setStateNames] = useState<Array<string>>([]);
    const [state, setState] = useState<State | null>(null);
    const [district, setDistrict] = useState<District | null>(null);
    const [allSelected, setAllSelected] = useState(false);
    const [districts, setDistricts] = useState<Array<District>>();

    // Fetching
    const [scenarios, fetchScenarios] = useScenarios();
    useEffect(() => {
        fetchScenarios();
    }, [fetchScenarios]);

    useEffect(() => {
        if (scenarioId) {
            getStates(scenarioId).then((state) => setStates(state));
        }
    }, [scenarioId]);

    useEffect(() => {
        if (state) {
            getDistricts(state.id.toString()).then((districts) => {
                const sortedDistricts = sortObjectsByName(districts);
                setDistricts(sortedDistricts);
            });
        }
    }, [state]);

    useEffect(() => {
        if (scenarioId) {
            setStateNames(getAllStateNames(states));
        }
    }, [scenarioId, states]);

    useEffect(() => {
        if (scenarioId || state || district) {
            const queryParams: Array<[string, string]> = [];
            if (scenarioId) {
                queryParams.push(["idScenario", scenarioId]);
            }
            if (state) {
                queryParams.push(["idState", state.id.toString()]);
            }
            if (district) {
                queryParams.push(["idDistrict", district.id.toString()]);
            }
            setSearchParams(queryParams);
        }
    }, [scenarioId, district, state, setSearchParams]);

    useEffect(() => {
        const idScenario = searchParams.get("idScenario");
        if (idScenario) {
            setScenarioId(idScenario);

            getStates(idScenario)
                .then((states) => {
                    setStates(states);
                    const idState = searchParams.get("idState");
                    if (idState) {
                        const selectedState = states.find((s: State) => s.id === parseInt(idState));
                        selectedState && setState(selectedState);
                        setAllSelected(false);
                        return getDistricts(idState);
                    }
                })
                .then((districts) => {
                    if (districts) {
                        const sortedDistricts = sortObjectsByName(districts);
                        setDistricts(sortedDistricts);
                        const idDistrict = searchParams.get("idDistrict");
                        if (idDistrict) {
                            const selectedDistrict = sortedDistricts.find((s: District) => s.id === parseInt(idDistrict));
                            selectedDistrict && setDistrict(selectedDistrict);
                        }
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state === null) {
            setAllSelected(true);
        }
    }, [state]);

    useEffect(() => {
        setStateNames(getAllStateNames(states));
    }, [states]);

    const getDashboardScenarioName = () => {
        if (scenarioId && scenarios) {
            return scenarios.find((s: any) => s.id === parseInt(scenarioId));
        }
        return undefined;
    };

    const getSelectedState = () => {
        if (states && state) {
            return states.find((s: any) => s.id === state.id);
        }
        return undefined;
    };
    const getActiveStates = () => {
        return states.filter((s) => s.active);
    };

    // Select functions for UI components
    const selectState = (state: State | null) => {
        setState(state);
        setDistrict(null);
        if (state === null) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    };

    const selectScenarioById = (scenarioId: string | null) => {
        setScenarioId(scenarioId);
        setAllSelected(true);
        setState(null);
        setDistrict(null);
    };

    return (
        <DashboardContext.Provider
            value={{
                scenarioId,
                selectScenarioById,
                scenarios,
                states,
                setStates,
                getDashboardScenarioName,
                getSelectedState,
                state,
                setState,
                selectState,
                district,
                setDistrict,
                stateNames,
                setStateNames,
                allSelected,
                districts,
                getActiveStates,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardContext;
