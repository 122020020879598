import { Autocomplete, FormControl, IconButton, MenuItem, Select, Switch, TableCell, TextField, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Bottler, UserContext } from "../context/UserContext";
import { useContext } from "react";
import { useUpdateUser } from "../api/userServices";

export const EditUserForm = (props: any) => {
    const { setForm, form, setOpenEditForm, editFormId } = props;
    const { bottlerList } = useContext(UserContext);
    const updateUser = useUpdateUser(editFormId, { successMessage: "User successfully updated.", errorMessage: "Error updating user." });

    return (
        <>
            <TableCell align="left"> {editFormId.id} </TableCell>
            <TableCell align="left">
                <TextField id="name" defaultValue={form.name} size="small" variant="standard" onChange={(e) => setForm({ ...form, name: e.target.value })} />
            </TableCell>
            <TableCell align="left">
                <TextField id="email" defaultValue={form.email} size="small" variant="standard" onChange={(e) => setForm({ ...form, email: e.target.value })} />
            </TableCell>
            <TableCell align="left">
                <Switch
                    checked={form.disabled ? false : true}
                    onChange={(e) => {
                        setForm({ ...form, disabled: !e.target.checked });
                    }}
                />
            </TableCell>
            <TableCell align="left">
                <FormControl variant="standard" sx={{ minWidth: "180px" }}>
                    <Select
                        size="small"
                        labelId="userRole"
                        id="user"
                        value={form.userRole}
                        label="User Role"
                        onChange={(e) => setForm({ ...form, userRole: e.target.value })}
                    >
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="CocaColaCompany">Coca-Cola Company</MenuItem>
                        <MenuItem value="Bottler">Bottler</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="left">
                <Autocomplete
                    sx={{ minWidth: "120px" }}
                    size="small"
                    options={bottlerList}
                    getOptionLabel={(option: Bottler) => option.name}
                    id="bottler"
                    value={{ id: form.bottlerId, name: form.bottlerName }}
                    onChange={(e: any, newValue: Bottler | null) => {
                        setForm({ ...form, bottlerId: newValue?.id, bottlerName: newValue?.name });
                    }}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />
            </TableCell>
            <TableCell align="right" sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <Tooltip title="Save Status">
                    <IconButton
                        onClick={() => {
                            updateUser(form);
                            setOpenEditForm(false);
                        }}
                    >
                        <CheckIcon sx={{ color: "green", cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel Change">
                    <IconButton onClick={() => setOpenEditForm(false)}>
                        <ClearIcon sx={{ color: "red", cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </>
    );
};
