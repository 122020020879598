export const darkColors = ["#8B0000", "#808080", "#474747", "#F40009", "#C00000", "#B3381C"];

export const byValueChainLinkColors = ["#FF847F", "#FFD8E0", "#F40009", "#474747", "#E5E5E5", "#f2f2f2"];
export const valueAdded2Colors = ["#F40009", "#FF847F", "#FFD8E0", "#f2f2f2"];

export const employmentChartByValueColors = ["#8B0000", "#FF847F", "#FFD8E0", "#F40009", "#474747", "#808080", "#E5E5E5"];
export const employmentChartBySectorColors = ["#8B0000", "#FF847F", "#FFD8E0", "#F40009"];
export const communityGivingPieChartColors = [
    "#C00000",
    "#B3381C",
    "#EC9886",
    "#F2BBAE",
    "#F9DDD7",
    "#B99525",
    "#E9D594",
    "#F1E3B8",
    "#F8F1DB",
    "#767171",
    "#AFABAB",
    "#D0CECE",
    "#F2F2F2",
];

export const getForegroundColor = (d: { color: string }): string => {
    if (darkColors.indexOf(d.color) !== -1) {
        return "white";
    } else {
        return "black";
    }
};
