import { useContext, useState } from "react";
import useAlert from "../hooks/useAlert";
import ImplanContext from "../context/ImplanContext";
import { uploadAndValidateFile, uploadImplanFile } from "../api/implanServices";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

type SingleFile = {
    mdFile: { name: string; type: string };
    onValidated: (file: any) => void;
    handleClose?: () => void;
    stateFile?: any;
    shouldValidate: boolean;
    fileName?: string;
};
export const SingleFileUpload = (props: SingleFile) => {
    const { mdFile, onValidated, stateFile, shouldValidate, fileName, handleClose } = props;

    const [isFileValid, setIsFileValid] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [fileError, setFileError] = useState<Array<string>>([]);

    const { setAlert } = useAlert();

    const { getImplanDataset, implanId } = useContext(ImplanContext);

    const handleFileInput = (event: any, shouldValidate: boolean) => {
        const formData = new FormData();
        if (mdFile.type === "districts" && stateFile) {
            formData.append(mdFile.type, event.target.files[0]);
            formData.append("states", stateFile);
        } else {
            if (mdFile.type === "districts" || mdFile.type === "states" || mdFile.type === "commodities" || mdFile.type === "industries") {
                formData.append(mdFile.type, event.target.files[0]);
            } else {
                formData.append("uploadFile", event.target.files[0]);
            }
        }

        setUploading(true);
        shouldValidate &&
            uploadAndValidateFile(mdFile.type, formData)
                .then((res) => {
                    setUploading(false);
                    if (res.message === "File is valid") {
                        onValidated(event.target.files[0]);
                        setIsFileValid(true);
                        setFileError([]);
                        setAlert("Successfully Uploaded", "success");
                    } else {
                        setIsFileValid(false);
                        setAlert(res.error, "error");
                        handleOnValidateErrors(mdFile.type, res);
                    }
                })
                .catch((err) => console.log(err.message));

        fileName && formData.append("fileName", fileName);
        !shouldValidate &&
            implanId &&
            uploadImplanFile(mdFile.type, formData, implanId)
                .then((result) => {
                    if (result.error) {
                        let errors = [result.error];
                        if (result.implanFileErrors) {
                            errors.push(...result.implanFileErrors);
                        }
                        setFileError(errors);
                    } else {
                        setAlert("Successfully Uploaded", "success");
                        getImplanDataset();

                        handleClose && handleClose();
                    }
                })
                .catch((err) => console.log(err));
    };

    const handleOnValidateErrors = (type: string, response: any) => {
        switch (type) {
            case "states":
                setFileError(response["stateErrors"]);
                break;
            case "districts":
                setFileError(response["districtErrors"]);
                break;
            case "industries":
                setFileError(response["industryErrors"]);
                break;
            case "commodities":
                setFileError(response["commodityErrors"]);
                break;
        }
    };
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" border="solid 1px #ddd" p={1} mt={1} flexWrap="wrap">
            <Typography color="secondary" width="60%">
                {mdFile.name} :
            </Typography>
            <Button disabled={isFileValid} variant="contained" component="label" color="primary" startIcon={<CloudUploadIcon />}>
                Upload {uploading && <CircularProgress sx={{ color: "white" }} size={16} />}
                <input hidden type="file" onChange={(e) => handleFileInput(e, shouldValidate)} />
            </Button>
            {fileError.map((error: string) => (
                <Typography display="block" sx={{ width: "100%" }} color="error" variant="caption">
                    {error}
                </Typography>
            ))}
        </Stack>
    );
};
