import { Stack, Typography } from "@mui/material";
import { PieChart } from "../charts/PieChart";
import { cardSettings } from "../widgets/constants";

type Props = {
    communityChartData: Array<{ label: string; value: number }>;
};
export const Community = ({ communityChartData }: Props) => {
    return (
        <Stack
            direction={cardSettings.direction}
            justifyContent={cardSettings.justifyContent}
            flexWrap={cardSettings.flexWrap}
            border={cardSettings.border}
            boxShadow={cardSettings.boxShadow}
            borderRadius={cardSettings.borderRadius}
            pt={5}
            pb={5}
            mt={25}
            zIndex={2}
            sx={{ backgroundColor: "white" }}
        >
            <Typography variant="h5" color="primary" fontWeight={600} mt={2} mb={2}>
                Community Contributions
            </Typography>
            <PieChart data={communityChartData} />
        </Stack>
    );
};
