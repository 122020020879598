import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { Bottler, UserContext } from "../context/UserContext";
import { CreateUserForm, useAddUser } from "../api/userServices";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CreateUser(props: any) {
    const { bottlerList } = useContext(UserContext);
    const { isOpen, setIsOpen } = props;
    const [form, setForm] = useState<CreateUserForm>({ disabled: false, userRole: "Bottler" });
    const addUser = useAddUser({ errorMessage: "Error creating user", successMessage: "Succesfully created user" });

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="dialog-title" open={isOpen}>
            <BootstrapDialogTitle id="dialog-title" onClose={handleClose}>
                Add New User
            </BootstrapDialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", minWidth: "500px" }} dividers>
                <TextField
                    id="name"
                    value={form.name}
                    label="Name"
                    size="small"
                    variant="standard"
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
                <TextField
                    id="email"
                    value={form.email}
                    label="Email"
                    size="small"
                    variant="standard"
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
                <FormControl variant="standard" sx={{ minWidth: "180px" }}>
                    <InputLabel id="userRole">User Role</InputLabel>
                    <Select
                        size="small"
                        labelId="userRole"
                        id="user"
                        value={form.userRole}
                        label="User Role"
                        onChange={(e) => setForm({ ...form, userRole: e.target.value })}
                    >
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="CocaColaCompany">Coca-Cola Company</MenuItem>
                        <MenuItem value="Bottler">Bottler</MenuItem>
                    </Select>
                </FormControl>
                {form.userRole === "Bottler" && (
                    <Autocomplete
                        sx={{ minWidth: "120px" }}
                        size="small"
                        options={bottlerList}
                        getOptionLabel={(option: Bottler) => option.name}
                        id="bottler"
                        value={form.bottlerName !== undefined && form.bottlerId !== undefined ? { id: form.bottlerId, name: form.bottlerName } : null}
                        onChange={(e: any, newValue: Bottler | null) => {
                            newValue && setForm({ ...form, bottlerId: newValue?.id, bottlerName: newValue?.name });
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Bottler" variant="standard" />}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => {
                        addUser(form);
                        handleClose();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
