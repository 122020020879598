import { Box, Stack, Typography } from "@mui/material";
import FactoryIcon from "@mui/icons-material/Factory";
import { CardItem } from "./CardItem";
import { dollarsToString } from "../utils/numberDisplay";
import { useContext } from "react";
import DashboardContext from "../context/DashboardContext";
import { OperationsType } from "../types/dashboard";
import { cardSettings } from "../widgets/constants";

export const Operations = ({ facilities, consumerSpending, suppliersSpending }: OperationsType) => {
    const { district, allSelected } = useContext(DashboardContext);

    const getDescription = () => {
        return !allSelected ? "Total bottling partners in state" : "Bottling partners";
    };

    const getValue = () => {
        if (district) {
            return facilities;
        } else {
            if (facilities) {
                return facilities;
            } else return "0";
        }
    };

    const operations = {
        production: { description: getDescription(), value: getValue() },
        consumet: { description: "Consumer retail spending value", value: dollarsToString(consumerSpending) },
        domestic: { description: "Spent on domestic suppliers", value: dollarsToString(suppliersSpending) },
    };

    return (
        <Box
            p={1}
            minHeight={235}
            sx={{
                borderRadius: cardSettings.smallCardBorderRadius,
                border: cardSettings.border,
                boxShadow: cardSettings.boxShadow,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography variant="h6" m={1}>
                    Operations
                </Typography>
                <FactoryIcon color="primary" sx={{ mb: 1 }} />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" alignItems="top" flexWrap="wrap" p={1} ml={1.5}>
                <CardItem styling="31%" value={operations.production.value.toString()} description={operations.production.description} />
                <CardItem styling={!allSelected ? "33%" : "36%"} value={operations.consumet.value.toString()} description={operations.consumet.description} />
                <CardItem styling="33%" value={operations.domestic.value.toString()} description={operations.domestic.description} />
            </Stack>
        </Box>
    );
};
