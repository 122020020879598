import {
    Autocomplete,
    Breadcrumbs,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Paths from "../Paths";
import useAlert from "../hooks/useAlert";
import { ImplanDataSet, deleteImplanDataSet, getImplanDataSets } from "../api/implanServices";

const Implan = () => {
    const [value, setValue] = useState(null);
    const [implanList, setImplanList] = useState<Array<ImplanDataSet> | []>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteRelatedFiles, setDeleteRelatedFiles] = useState(false);
    const [deleteDataId, setDeleteDataId] = useState("");

    const navigate = useNavigate();
    const { setAlert } = useAlert();

    useEffect(() => {
        if (value) {
            setImplanList([value]);
        } else {
            getImplanDataSets().then((implanDataSets) => setImplanList(implanDataSets));
        }
    }, [value]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={Paths.implan.path}>
                    Admin
                </Link>
                <Typography color="text.primary">Implan</Typography>
            </Breadcrumbs>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Autocomplete
                    sx={{ minWidth: "75%" }}
                    options={implanList}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option: any) => option.name}
                    value={value}
                    onChange={(event: any, newValue: any) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Search implan data" variant="standard" />}
                />
                <Tooltip title="Create New Data" followCursor>
                    <Button variant="outlined" size="small" onClick={() => navigate(Paths.implanCreate.path)}>
                        Create New Data
                    </Button>
                </Tooltip>
            </Stack>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Dataset name</TableCell>
                            <TableCell>Dataset Status</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {implanList &&
                            implanList.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        transition: "ease all .5s",
                                        "&:hover": { backgroundColor: "#f5f5f5" },
                                        cursor: "pointer",
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link color="inherit" to={`${Paths.implan.path}/${row.id}`}>
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.valid && (
                                            <Tooltip title="Valid">
                                                <CheckIcon color="success" />
                                            </Tooltip>
                                        )}
                                        {!row.valid && (
                                            <Tooltip title="Invalid">
                                                <CloseIcon color="error" />
                                            </Tooltip>
                                        )}
                                        {row.locked && (
                                            <Tooltip title="Locked">
                                                <LockIcon color="info" />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {!row.locked && (
                                            <Tooltip title="Delete Data">
                                                <DeleteIcon
                                                    color="error"
                                                    onClick={() => {
                                                        row.id && setDeleteDataId(row.id);
                                                        setOpenDialog(true);
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                        {openDialog && (
                                            <Dialog
                                                open={openDialog}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">{"Do you really want to delete implan data?"}</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Delete also related files.
                                                        <Checkbox value={deleteRelatedFiles} onChange={(e) => setDeleteRelatedFiles(e.target.checked)} />
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Cancel</Button>
                                                    <Button
                                                        autoFocus
                                                        onClick={() => {
                                                            setOpenDialog(false);
                                                            deleteImplanDataSet(deleteDataId, deleteRelatedFiles)
                                                                .then((res) => {
                                                                    if (res.message) {
                                                                        setAlert("Successfully Deleted", "success");
                                                                        getImplanDataSets().then((implanDataSets) => setImplanList(implanDataSets));
                                                                    }
                                                                })
                                                                .catch((err) => console.log(err));
                                                        }}
                                                    >
                                                        Ok
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Implan;
