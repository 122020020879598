import { Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
type Props = {
    value: string;
    description: string;
    styling: string;
};
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // Define your default font size here
        fontSize: "2rem", // You can adjust this as needed

        //@ts-ignore it is expected we added new custom breakpoint
        [theme.breakpoints.down("cb")]: {
            fontSize: "1.5rem", // Font size for medium screens (md and up)
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "2rem", // Font size for medium screens (md and up)
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem", // Font size for medium screens (md and up)
        },
    },
}));
export const CardItem = ({ value, description, styling }: Props) => {
    const classes = useStyles();
    return (
        <Box width={styling} mt={2.3}>
            <Typography className={classes.root} color="primary">
                {value}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                {description}
            </Typography>
        </Box>
    );
};
