import { Box, Typography } from "@mui/material";
import { chartTitleStyling, getChartBoxStyling } from "../charts/styling";
import { BarChart } from "../charts/BarChart";
import { byValueChainLinkColors } from "../charts/colors";
import { millionsToString } from "../utils/numberDisplay";

type Props = {
    height: number;
    byValueChainLink: any;
};
export const ValueAddedLevelChart = ({ height, byValueChainLink }: Props) => {
    return (
        <Box sx={getChartBoxStyling(height)}>
            <Typography component="span" sx={chartTitleStyling}>
                Value added supported by level <span>(USD million)</span>
            </Typography>
            <BarChart
                data={byValueChainLink}
                indexBy="type"
                keys={[
                    "Direct suppliers",
                    "Suppliers' suppliers",
                    "The Coca-Cola system",
                    "Direct trade outlets",
                    "Trade outlets' suppliers",
                    "Consumer sales tax",
                ]}
                colors={byValueChainLinkColors}
                title="Value added supported by level"
                boldSpan="(USD million)"
                isSystemCleared={true}
                numberFormatter={millionsToString}
                legendTotalInOneColumn={2}
            />
        </Box>
    );
};
