import { Box } from "@mui/material";
import { UsStates } from "./UsStates";
import "./Map.css";

export type MapProps = {
    stateNames: any;
};

export const Map = ({ stateNames }: MapProps) => {
    return (
        <Box height={600} width={959} m="auto" id="map" className="browserMap">
            <UsStates stateNames={stateNames} />
        </Box>
    );
};
