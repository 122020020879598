import { API_URI } from "../utils/urls";

export type ImplanDataSet = {
    id: string;
    name: string;
    creationDate: string;
    valid: boolean;
    locked: boolean;
    state: "New" | "Valid" | "Preprocessing" | "Ready" | "Used";
    masterDataSetId?: number;
    masterDataSetName?: string;
    implanFiles: ImplanFiles;
};
export interface ImplanFiles {
    IMR_DATA: ImplanData;
    TRADE_DATA: ImplanData;
    BOTTLER_STATES: ImplanData;
    MULTIPLIER_DATA: ImplanData;
    STATE_DISTRICT_POPULATION_DATA: ImplanData;
    COMMUNITY_GIVING: ImplanData;
    ABSORPTION_DATA: ImplanData;
    COMMODITY_DEMAND_DATA: ImplanData;
    INPUT_DIRECT: ImplanData;
    S_INPUT_DOWNSTREAM: ImplanData;
    S_INPUT_UPSTREAM: ImplanData;
    MARKET_SHARE_DATA: ImplanData;
}

export type MasterDataSet = {
    id: number;
    name: string;
    creationDate: string;
};

export type ImplanFormData = {
    id?: string;
    name?: string;
    masterData?: any;
    MULTIPLIER_DATA?: ImplanData;
    ABSORPTION_DATA?: ImplanData;
    TRADE_DATA?: ImplanData;
    COMMODITY_DEMAND_DATA?: ImplanData;
    MARKET_SHARE_DATA?: ImplanData;
    IMR_DATA?: ImplanData;
    STATE_DISTRICT_POPULATION_DATA?: ImplanData;
};

export interface ImplanData {
    id: number;
    name: string;
    type: string;
    creationDate: string;
    consistency: Consistency | null;
}

export interface Consistency {
    implanFileId: number;
    masterDataSetId: number;
    consistent: boolean;
    errors: any[];
}
export async function getImplanDataSets(): Promise<Array<ImplanDataSet>> {
    const response = await fetch(`${API_URI}/implandataset/all`);
    return await response.json();
}

export async function createImplanDataSet(name: { name: string }): Promise<Array<ImplanDataSet>> {
    const response = await fetch(`${API_URI}/implandataset/add`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(name),
    });
    return await response.json();
}

export async function updateImplanDataSet(name: { name: string }, id: string): Promise<Array<ImplanDataSet>> {
    const response = await fetch(`${API_URI}/implandataset/${id}/update`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(name),
    });
    return await response.json();
}

export async function getImplanDataSet(implanDataSetId: string): Promise<ImplanDataSet> {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}`);
    return await response.json();
}

export async function deleteImplanDataSet(implanDataSetId: string, deleteRelatedFiles: boolean) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/delete?deleteFiles=${deleteRelatedFiles}`, {
        method: "DELETE",
    });
    return await response.json();
}

export async function getMasterDataSets(): Promise<Array<MasterDataSet>> {
    const response = await fetch(`${API_URI}/masterdataset/all`);
    return await response.json();
}

export async function uploadAndValidateFile(type: string, file: BodyInit): Promise<any> {
    const response = await fetch(`${API_URI}/masterdataset/validate/${type}`, {
        method: "POST",
        body: file,
    });
    return await response.json();
}

export async function deleteExistingMasterDataSet(implanDataSetId: string, masterDataSetId: string) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/masterdataset/${masterDataSetId}/delete`, {
        method: "DELETE",
    });
    return await response.json();
}

type UploadImplanFileResponse = {
    error?: string;
    implanFileErrors?: Array<string>;
};

export async function uploadImplanFile(type: string, file: BodyInit, implanDataSetId: string): Promise<UploadImplanFileResponse> {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/files/${type}/upload`, {
        method: "POST",
        body: file,
    });
    return await response.json();
}

export async function createMasterDataSetFiles(implanDataSetId: string, file: any) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/masterdataset/create`, {
        method: "POST",
        body: file,
    });
    return await response.json();
}

export async function linkMasterDatasetToImplanDataSet(implanDataSetId: string, masterDataSetid: string) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/masterdataset/${masterDataSetid}/add`, {
        method: "PUT",
    });
    return await response.json();
}

export async function unlinkMasterDatasetToImplanDataSet(implanDataSetId: string) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/masterdataset/remove`, {
        method: "PUT",
    });
    return await response.json();
}

export async function linkImplanFileToImplanDataSet(implanDataSetId: string, fileId: string) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/files/${fileId}/add`, {
        method: "PUT",
    });
    return await response.json();
}

export async function unlinkImplanFileToImplanDataSet(implanDataSetId: string, type: string) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/files/${type}/remove`, {
        method: "PUT",
    });
    return await response.json();
}

export async function getFileData(type: string) {
    const response = await fetch(`${API_URI}/implanfile/${type}/all`);
    return await response.json();
}

export async function checkConsistency(dataSetId: string, fileId: string) {
    const response = await fetch(`${API_URI}/implandataset/${dataSetId}/files/${fileId}/checkconsistency`, {
        method: "POST",
    });
    return await response.json();
}

export async function deleteExistingImplanFileData(implanDataSetId: string, fileId: string) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/files/${fileId}/delete`, {
        method: "DELETE",
    });
    return await response.json();
}

export async function startPreprocess(implanDataSetId: string) {
    const response = await fetch(`${API_URI}/implandataset/${implanDataSetId}/preprocess`, {
        method: "POST",
    });
    return await response.json();
}
