import { useState } from "react";
import { API_URI } from "../utils/urls";
import { FetchOptions, useFetch } from "../hooks/useFetch";
import { Commodity, Industry, State } from "../pages/ScenarioDetail";

export type ScenarioStatus = "NEW" | "RUNNING" | "FINISHED_SUCCESSFUL" | "FINISHED_WITH_ERRORS" | "PUBLISHED";

export type ScenarioData = {
    id: string;
    name: string;
    creationDate: string;
    status: ScenarioStatus;
    commodities: Array<Commodity>;
    states: Array<State>;
    industries: Array<Industry>;
    implanDataSetId: string;
    implanDataSetName: string;
    stoppingThreshold: number;
    maxBottlersNo: number;
    stoppingMethodId: string;
    stoppingMethodName: string;
};

export type ScenarioForm = {
    id?: string;
    name?: string;
    creationDate?: string;
    status?: ScenarioStatus;
    commodities?: Array<Commodity>;
    states?: Array<State>;
    industries?: Array<Industry>;
    implanDataSetId?: string;
    implanDataSetName?: string;
    stoppingThreshold?: number;
};

export function useScenarios(): [Array<ScenarioData>, () => void] {
    const [scenarios, setScenarios] = useState<Array<ScenarioData>>([]);
    const { get } = useFetch(`${API_URI}/scenario/all`, setScenarios);
    return [scenarios, get];
}

export async function createScenario(name: { name: string }): Promise<Array<ScenarioData>> {
    const response = await fetch(`${API_URI}/scenario/add`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(name),
    });
    return await response.json();
}
export async function getScenario(scenarioId: string): Promise<ScenarioData> {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}`);
    return await response.json();
}

export async function linkImplanDataSetToScenario(implanDataSetId: string, scenarioId: string) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/implandataset/${implanDataSetId}/add`, {
        method: "PUT",
    });
    return await response.json();
}

export async function updateScenarioNameAndMaxBottlers(scenarioId: string, data: { name: string; maxBottlersNo: number | string | null }) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/update`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    return await response.json();
}

export function usePublishScenario(scenarioId: string, onReceived: (value: any) => void, options: FetchOptions) {
    const { put } = useFetch(`${API_URI}/scenario/${scenarioId}/publish`, onReceived, options);
    return put;
}

export function useUnpublishScenario(scenarioId: string, onReceived: (value: any) => void, options: FetchOptions) {
    const { put } = useFetch(`${API_URI}/scenario/${scenarioId}/unpublish`, onReceived, options);
    return put;
}

export async function deleteScenario(scenarioId: string) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/delete`, {
        method: "DELETE",
    });
    return await response.json();
}

export async function updateScenarioScopes(scenarioId: string, scopeType: "commodity" | "state" | "industry", scopeId: number, checked: boolean) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/${scopeType}/${scopeId}/toggle/${checked}`, {
        method: "PUT",
    });
    return await response.json();
}

export async function getStoppingMethods() {
    const response = await fetch(`${API_URI}/scenario/parameters/stoppingmethod/all`);
    return await response.json();
}

export async function linkStoppingMethodToScenario(stoppingMethodId: string, scenarioId: string) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/parameters/stoppingmethod/${stoppingMethodId}/add`, {
        method: "PUT",
    });
    return await response.json();
}

export async function linkStoppingThresholdToScenario(stoppingThresholdValue: number, scenarioId: string) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/parameters/stoppingthreshold/${stoppingThresholdValue}/add`, {
        method: "PUT",
    });
    return await response.json();
}

export async function startScenario(scenarioId: string) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/start`, {
        method: "POST",
    });
    return await response.json();
}
export async function stopScenario(scenarioId: string) {
    const response = await fetch(`${API_URI}/scenario/${scenarioId}/stop`, {
        method: "POST",
    });
    return await response.json();
}
