import PersonIcon from "@mui/icons-material/Person";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import { Box, Stack, Typography } from "@mui/material";
import { imageSizes } from "../components/ExportPdf";

export const GenerateGridWithIcons = ({ employmentNumber, scale }: { employmentNumber: number; scale: number }) => {
    const { height, width } = imageSizes.employmentRatio;

    const rows = 4;
    const columns = 5;

    const unscaledIconSizeInPx = Math.min(height / rows, width / (columns + 2));
    const scaledIconSizeInPx = unscaledIconSizeInPx * scale;
    const iconSize = `${scaledIconSizeInPx}px`;
    const fontSize = `${scaledIconSizeInPx}px`;

    const max = 20;

    const displayedIcon = Math.min(max, employmentNumber);
    const overflow = displayedIcon < employmentNumber;

    // Create an array of length equal to the number of icons that are displayed
    const iconsArray = new Array(displayedIcon).fill(null);
    return (
        <Box id="iconGroup" width={width * scale} height={height * scale}>
            <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="100%">
                <PersonIcon sx={{ height: iconSize, width: iconSize }} />
                <Typography fontSize={fontSize} sx={{ lineHeight: 1 }}>
                    =
                </Typography>
                <Stack width="100%" height="100%" direction="row" alignItems="center" justifyContent="center" flexWrap="wrap">
                    {iconsArray.map((_, index) => {
                        if (overflow && index === max - 1) {
                            return <MoreIcon key={index} sx={{ color: "red", width: iconSize, height: iconSize }} />;
                        } else {
                            return <PersonIcon key={index} sx={{ color: "red", width: iconSize, height: iconSize }} />;
                        }
                    })}
                </Stack>
            </Stack>
        </Box>
    );
};
