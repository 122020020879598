import { compile } from "path-to-regexp";

import config from "./config";

type Path = {
    path: string;
    toPath: Function;
};

function createPathObject(path: string): Path {
    return {
        path: path,
        toPath: compile(path),
    };
}

export const root = createPathObject(config.baseurl);

export const admin = createPathObject(config.baseurl + "admin");
export const dashboard = createPathObject(config.baseurl + "dashboard");
export const implan = createPathObject(config.baseurl + "admin/implan");
export const implanCreate = createPathObject(config.baseurl + "admin/implan/create");
export const implanUpdate = createPathObject(config.baseurl + "admin/implan/update/:id");
export const implanDetails = createPathObject(config.baseurl + "admin/implan/:id");
export const scenarios = createPathObject(config.baseurl + "admin/scenarios");
export const users = createPathObject(config.baseurl + "admin/users");
export const bottlers = createPathObject(config.baseurl + "admin/bottlers");

export const scenarioDetails = createPathObject(config.baseurl + "admin/scenarios/:id");
export const login = createPathObject(config.baseurl + "rest/auth/login");
export const userInfo = createPathObject(config.baseurl + "rest/users/userinfo");
export const loggedIn = createPathObject(config.baseurl + "rest/auth/loggedIn");

export const logout = createPathObject(config.baseurl + "rest/auth/logout");
export const home = createPathObject(config.baseurl + "home");
