import { Box, Checkbox, Divider, FormControlLabel, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateScenarioScopes } from "../../api/scenarioServices";
import useAlert from "../../hooks/useAlert";
import { Commodity } from "../../pages/ScenarioDetail";

type Props = { commodities: Array<Commodity>; setCommodities: (commodities: Array<Commodity>) => void; editable: boolean };

const ScenarioCommodities = ({ commodities, setCommodities, editable }: Props) => {
    const [searchCommodity, setSearchCommodity] = useState("");
    const [filteredCommodities, setFilteredCommodities] = useState<Array<Commodity> | []>([]);

    const { setAlert } = useAlert();
    const { id } = useParams();
    const scenarioId = id;

    useEffect(() => {
        if (searchCommodity.length > 1 && commodities) {
            setFilteredCommodities(commodities.filter((i) => i.name.toLowerCase().includes(searchCommodity)));
        } else if (searchCommodity === "") {
            setFilteredCommodities(commodities);
        }
    }, [commodities, searchCommodity]);

    const handleCommodityChange = useCallback(
        (id: number) => {
            const updatedCommodities = commodities.map((commodity) => {
                if (commodity.id === id) {
                    scenarioId &&
                        updateScenarioScopes(scenarioId, "commodity", id, !commodity.checked)
                            .then((res) => {
                                if (res.error) {
                                    setAlert(res.error, "error");
                                    return commodity;
                                }
                            })
                            .catch((err) => console.log(err));
                    return { ...commodity, checked: !commodity.checked };
                } else {
                    return commodity;
                }
            });
            setCommodities(updatedCommodities);
        },
        [commodities, setCommodities, scenarioId, setAlert]
    );

    return (
        <Box width="32%" ml="1rem" overflow="auto">
            <Typography sx={{ textDecoration: "underline" }}>Commodities</Typography>
            <TextField
                disabled={!editable}
                sx={{ mt: 1, mb: 1, width: "90%" }}
                size="small"
                label="Search Commodity"
                variant="outlined"
                value={searchCommodity}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => setSearchCommodity(e.target.value)}
            />
            {filteredCommodities.map((commodity, i) => {
                return (
                    <Stack key={i} direction="row" justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                            label={commodity.name}
                            control={<Checkbox disabled={!editable} checked={commodity.checked} onChange={() => handleCommodityChange(commodity.id)} />}
                        />
                        <Divider />
                    </Stack>
                );
            })}
        </Box>
    );
};
export default memo(ScenarioCommodities);
