import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as Paths from "./Paths";
import Scenario from "./pages/Scenario";
import { ScopedCssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { ManageImplanData } from "./pages/ManageImplanData";
import { AlertProvider } from "./context/AlertContext";
import AlertPopup from "./components/AlertPopup";
import ScenarioDetail from "./pages/ScenarioDetail";
import { Dashboard } from "./pages/Dashboard";
import { ImplanProvider } from "./context/ImplanContext";
import AdminPage from "./pages/Admin";
import Implan from "./pages/Implan";
import { DashboardProvider } from "./context/DashboardContext";
import CheckAuth from "./auth/CheckAuth";
import { Widgets } from "./pages/Widgets";
import Bottlers from "./pages/Bottlers";
import Users from "./pages/Users";
import UserProvider from "./context/UserContext";
import Glossary from "./pages/Glossary";

const theme = createTheme({
    typography: {
        fontFamily: "Inter, Arial",
        h6: {
            fontWeight: 600,
        },
        caption: {
            fontWeight: 500,
            fontSize: "13px",
            lineHeight: "120%",
        },
    },
    components: {
        MuiBottomNavigation: {
            defaultProps: {
                style: {
                    backgroundColor: "#F40009",
                },
            },
        },
        MuiBottomNavigationAction: {
            defaultProps: {
                style: {
                    color: "#FFFFFF",
                    fontWeight: "bolder",
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#F40009",
        },
        secondary: {
            main: "#212121",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            // Custom breakpoint
            //@ts-ignore
            cb: 1560,
            xl: 1920,
        },
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <AlertProvider>
                <CheckAuth>
                    <ImplanProvider>
                        <AlertPopup />
                        <ScopedCssBaseline>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/">
                                        <Route path={Paths.admin.path} element={<AdminPage />}>
                                            <Route path={Paths.implan.path} element={<Implan />} />
                                            <Route path={Paths.implanDetails.path} element={<ManageImplanData />} />
                                            <Route path={Paths.implanCreate.path} element={<ManageImplanData />} />
                                            <Route path={Paths.implanUpdate.path} element={<ManageImplanData />} />
                                            <Route path={Paths.scenarios.path} element={<Scenario />} />
                                            <Route path={Paths.scenarioDetails.path} element={<ScenarioDetail />} />
                                            <Route
                                                path={Paths.users.path}
                                                element={
                                                    <UserProvider>
                                                        <Users />
                                                    </UserProvider>
                                                }
                                            />
                                            <Route
                                                path={Paths.bottlers.path}
                                                element={
                                                    <UserProvider>
                                                        <Bottlers />
                                                    </UserProvider>
                                                }
                                            />
                                        </Route>

                                        <Route
                                            path={Paths.dashboard.path}
                                            element={
                                                <DashboardProvider>
                                                    <Dashboard />
                                                </DashboardProvider>
                                            }
                                        />
                                        <Route path={"widgets"} element={<Widgets />} />
                                        <Route path={"glossary"} element={<Glossary />} />

                                    </Route>
                                    <Route index element={<Navigate to={Paths.dashboard.path} />} />
                                </Routes>
                            </BrowserRouter>
                        </ScopedCssBaseline>
                    </ImplanProvider>
                </CheckAuth>
            </AlertProvider>
        </ThemeProvider>
    );
};

export default App;
