import { Box, Checkbox, Divider, FormControlLabel, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateScenarioScopes } from "../../api/scenarioServices";
import useAlert from "../../hooks/useAlert";
import { Industry } from "../../pages/ScenarioDetail";

type Props = { industries: Array<Industry>; setIndustries: (industries: Array<Industry>) => void; editable: boolean };

const ScenarioIndustries = ({ industries, setIndustries, editable }: Props) => {
    const [searchIndustry, setSearchIndustry] = useState("");
    const [filteredIndustries, setFilteredIndustries] = useState<Array<Industry> | []>([]);

    const { setAlert } = useAlert();
    const { id } = useParams();
    const scenarioId = id;

    useEffect(() => {
        if (searchIndustry.length > 1 && industries) {
            setFilteredIndustries(industries.filter((i) => i.name.toLowerCase().includes(searchIndustry.toLowerCase())));
        } else if (searchIndustry === "") {
            setFilteredIndustries(industries);
        }
    }, [industries, searchIndustry]);

    const handleIndustryChange = useCallback(
        (id: number) => {
            const updatedIndustries = industries.map((industry) => {
                if (industry.id === id) {
                    scenarioId &&
                        updateScenarioScopes(scenarioId, "industry", id, !industry.checked)
                            .then((res) => {
                                if (res.error) {
                                    setAlert(res.error, "error");
                                    return industry;
                                }
                            })
                            .catch((err) => console.log(err));
                    return { ...industry, checked: !industry.checked };
                } else {
                    return industry;
                }
            });
            setIndustries(updatedIndustries);
        },
        [industries, setIndustries, scenarioId, setAlert]
    );

    return (
        <Box width="32%" ml="1rem" overflow="auto">
            <Typography sx={{ textDecoration: "underline" }}>Industries</Typography>
            <TextField
                disabled={!editable}
                sx={{ mt: 1, mb: 1, width: "90%" }}
                size="small"
                label="Search Industry"
                variant="outlined"
                value={searchIndustry}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => setSearchIndustry(e.target.value)}
            />

            {filteredIndustries.map((industry, i) => {
                return (
                    <Stack key={i} direction="row" justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                            label={industry.name}
                            control={<Checkbox disabled={!editable} checked={industry.checked} onChange={() => handleIndustryChange(industry.id)} />}
                        />
                        <Divider />
                    </Stack>
                );
            })}
        </Box>
    );
};
export default memo(ScenarioIndustries);
