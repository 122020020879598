import { API_URI } from "../utils/urls";
import { FetchOptions, useFetch } from "../hooks/useFetch";
import { Bottler, User } from "../context/UserContext";
import { useState } from "react";

export type CreateUserForm = {
    id?: number;
    name?: string;
    email?: string;
    disabled?: boolean;
    userRole: "Admin" | "CocaColaCompany" | "Bottler" | string;
    bottlerId?: number;
    bottlerName?: string;
};

export function useUsers(): [Array<User>, () => void] {
    const [users, setUsers] = useState<Array<User>>([]);
    const { get } = useFetch(`${API_URI}/users/all`, setUsers);
    return [users, get];
}

export function useAddUser(options: FetchOptions) {
    const { post } = useFetch(`${API_URI}/users/add`, (_: any) => {}, options);
    return post;
}

export function useUpdateUser(userId: number, options: FetchOptions) {
    const { put } = useFetch(`${API_URI}/users/${userId}/update`, (_) => {}, options);
    return put;
}

export function useDeleteUser(userId: number | undefined, options: FetchOptions) {
    const { del } = useFetch(`${API_URI}/users/${userId}/delete`, (_) => {}, options);
    return del;
}

export function useBottler(): [Array<Bottler>, (bottlerList: Array<Bottler>) => void, () => void] {
    const [bottlers, setBottlers] = useState<Array<Bottler>>([]);
    const { get } = useFetch(`${API_URI}/bottler/all`, setBottlers);
    return [bottlers, setBottlers, get];
}

export function useAddBottler(options: FetchOptions) {
    const { post } = useFetch(`${API_URI}/bottler/add`, (_: any) => {}, options);
    return post;
}

export async function deleteBottler(bottlerId: number) {
    const response = await fetch(`${API_URI}/bottler/${bottlerId}/delete`, {
        method: "DELETE",
    });
    return response;
}
