type CardSettingsType = {
    direction: "column" | "column-reverse" | "row" | "row-reverse" | undefined;
    justifyContent: any;
    flexWrap: any;
    border: any;
    boxShadow: any;
    borderRadius: any;
    smallCardBorderRadius: any;
};
export const cardSettings: CardSettingsType = {
    direction: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    border: "1px solid #ddd",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "18px",
    smallCardBorderRadius: "9px",
};
