import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
    isOpen: boolean;
    setIsOpen: (a: boolean) => void;
    modalTitle: string;
    modalContent: string;
    handleAgree: () => void;
};

export default function AlertDialog(props: Props) {
    return (
        <>
            {props.isOpen && (
                <Dialog
                    open={props.isOpen}
                    onClose={() => props.setIsOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{props.modalTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{props.modalContent}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => props.setIsOpen(false)}>Cancel</Button>
                        <Button onClick={props.handleAgree} autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
