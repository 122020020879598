import { Box, Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Operations } from "../components/Operations";
import { EconomicImpact } from "../components/EconomicImpact";
import { EmploymentImpact } from "../components/EmploymentImpact";
import { CommunityGiving } from "../components/CommunityGiving";
import { Map } from "../charts/map/Map";
import { DashboardSelectGroup } from "../components/DashboardSelectGroup";
import { ValueAdded } from "../components/ValueAdded";
import { Employment } from "../components/Employment";
import { useContext } from "react";
import { Community } from "../components/Community";
import DownloadIcon from "@mui/icons-material/Download";

import Header from "../components/Header";
import indigo from "../assets/Indigo.png";
import bubbles from "../assets/bubbles.png";
import "./dashboard.css";

import DashboardContext from "../context/DashboardContext";
import { Footer } from "../components/Footer";
import { Results } from "../types/dashboard";

type ExportPdfProps = {
    createPdf: () => void;
};

const ExportPdf = ({ createPdf }: ExportPdfProps) => {
    return (
        <Button variant="contained" color="primary" onClick={createPdf} disabled={false}>
            Download Full Report
            <DownloadIcon />
        </Button>
    );
};

type Props = {
    result: Results | null | undefined;
    createPdf: () => void;
};

export const DashboardBrowser = ({ result, createPdf }: Props) => {
    const { district, getDashboardScenarioName, getSelectedState, state, stateNames, allSelected } = useContext(DashboardContext);
    const scenarioName = getDashboardScenarioName();
    const selectedStateData = getSelectedState();
    const isLargeScreen = useMediaQuery("(min-width: 1400px)");
    const getSelectedStateAndDistrictName = () => {
        if (allSelected) {
            return "United States";
        } else {
            if (district) {
                return `${selectedStateData?.name} > ${district.name}`;
            } else {
                return selectedStateData?.name;
            }
        }
    };

    return (
        <Box position="relative">
            <Header />
            <Box mt={9} mb={8} p={4}>
                <Stack direction="column" width={isLargeScreen ? "75%" : "100%"} m="auto">
                    <Typography
                        variant="h4"
                        id="header"
                        component="span"
                        sx={{
                            span: { color: "red" },
                            fontWeight: 600,
                        }}
                    >
                        The Economic Impact of the Coca-Cola System in <span>{scenarioName ? scenarioName.name : ""}</span>
                    </Typography>
                    <Typography
                        id="headerDescription"
                        mt={3}
                        variant="subtitle1"
                        width="100%"
                        component="span"
                        sx={{
                            span: { color: "red" },
                        }}
                    >
                        Coca-Cola creates global reach with a local focus because of the strength of the Coca-Cola system, which comprises The Coca-Cola Company
                        and its bottling partners. Through marketing, manufacturing, packaging, and distributing branded products to customers, the Coca-Cola
                        system generates significant direct, indirect, and induced impacts on economies. The results displayed in this tool were calculated
                        using a form of economic analysis called multi-regional input-output modelling. Explore the impact below.
                    </Typography>
                </Stack>
                <Stack
                    width={isLargeScreen ? "76%" : "100%"}
                    direction="row"
                    alignItems="center"
                    sx={{ position: "sticky", top: { xs: "2.7rem", md: "3.5rem" }, backgroundColor: "white" }}
                    m="auto"
                    zIndex={9}
                >
                    <DashboardSelectGroup />
                </Stack>
                <Grid container item direction="column" alignItems="flex-start" justifyContent="flex-start" lg={9} md={12} xs={12} m="auto">
                    {state && (
                        <Box>
                            <Typography variant="h5">{!allSelected ? selectedStateData?.name : "United States"}</Typography>
                        </Box>
                    )}
                    {district && (
                        <Box>
                            <Typography variant="h6">{!allSelected && district?.name}</Typography>
                        </Box>
                    )}
                </Grid>

                <Map stateNames={stateNames} />
                <Grid container item direction="column" lg={9} md={12} xs={12} m="auto">
                    <Grid mt={3} container item justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={12} lg={6}>
                            {result && <Operations {...result?.summary.operations} />}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {result && <EconomicImpact {...result?.summary.economicImpact} getSelectedStateAndDistrictName={getSelectedStateAndDistrictName} />}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {result && (
                                <EmploymentImpact {...result?.summary.employmentImpact} getSelectedStateAndDistrictName={getSelectedStateAndDistrictName} />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {result && (
                                <CommunityGiving {...result?.summary.communityGiving} getSelectedStateAndDistrictName={getSelectedStateAndDistrictName} />
                            )}
                        </Grid>
                    </Grid>
                    {result && (
                        <Stack mt={22}>
                            <ValueAdded height={500} valueAddedChartData={result.valueAdded} />
                            <Box position="relative">
                                <img className="indigo" src={indigo} alt="bg"></img>
                            </Box>
                            <Employment height={500} employmentChartData={result.employment} />
                            <Box position="relative">
                                <img className="bubbles" src={bubbles} alt="bg"></img>
                            </Box>
                            <Community communityChartData={result.communityGiving} />

                            <Stack direction="row" alignItems="center" justifyContent="center" my={4} py={3}>
                                <ExportPdf createPdf={createPdf} />
                            </Stack>
                        </Stack>
                    )}
                </Grid>
            </Box>
            <Footer />
        </Box>
    );
};
