export const getDate = () => {
    function getOrdinalSuffix(number: any) {
        const suffixes = ["th", "st", "nd", "rd"];
        const lastDigit = number % 10;
        const suffix = lastDigit >= 1 && lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13 ? suffixes[lastDigit] : suffixes[0];
        return suffix;
    }
    const date = new Date();

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    const formattedDate = `${day}${ordinalSuffix} of ${month} ${year}`;
    return formattedDate;
};
