import FormControl from "@mui/material/FormControl";
import { Chip, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import DashboardContext from "../context/DashboardContext";
import { UserInfoContext } from "../context/UserInfoContext";

export default function ScenarioSelector() {
    const userInfo = useContext(UserInfoContext);
    const { scenarioId, scenarios, selectScenarioById } = useContext(DashboardContext);

    return (
        <FormControl fullWidth>
            <InputLabel id="singleSelect" shrink={true}>
                Year
            </InputLabel>
            <Select
                labelId="singleSelect"
                id="scenarioSelect"
                size="small"
                value={scenarioId ? scenarioId : ""}
                notched={true}
                label="Scenario"
                onChange={(e) => {
                    const id = e.target.value;
                    selectScenarioById(id);
                }}
            >
                {scenarios.map((scenario) => {
                    return (
                        <MenuItem key={scenario.id} value={scenario.id}>
                            {scenario.name}
                            {userInfo?.userRole === "Admin" && <Chip size="small" sx={{ ml: "0.3rem", maxHeight: "20px" }} label={scenario.status} />}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
