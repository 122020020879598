import { Box, Stack, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { millionsToString, thousandsToString } from "../utils/numberDisplay";
import { communityGivingPieChartColors, getForegroundColor } from "./colors";

type Props = {
    data: Array<{ label: string; value: number }>;
};

export const getConvertedData = (chartData: Array<{ label: string; value: number }>) => {
    chartData = chartData.sort((a, b) => b.value - a.value);
    const converted = chartData.map((d, i) => {
        return { id: i, label: d.label, value: d.value };
    });
    const convertedByValue = converted.filter((e) => e.value !== 0);
    const convertedLabels = convertedByValue.map((d) => d.label);
    return {
        data: convertedByValue,
        legends: convertedLabels,
    };
};

export const PieChart = ({ data }: Props) => {
    const chartData = getConvertedData(data);
    const total = data.reduce((total, item) => item.value + total, 0);

    const chartTheme = {
        fontFamily: "Inter, Arial, sans-serif",
        labels: {
            text: {
                fontSize: 14,
                fontWeight: 500,
            },
        },
    };

    return (
        <Box height={600} width="100%" display="flex" flexDirection="column" justifyItems="center" alignItems="center">
            <Typography>by focus area in % and $</Typography>
            <Box display="flex" flexDirection="column" justifyItems="space-between" alignItems="center" width="100%">
                {/* Check if there is data to show pie chart */}
                {!chartData.data.every((c) => c.value === 0) ? (
                    <Box height={400} width="60%">
                        <ResponsivePie
                            // show only non-zero values in chart
                            data={chartData.data}
                            margin={{ top: 40, right: 120, bottom: 80, left: 80 }}
                            sortByValue={true}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            theme={chartTheme}
                            activeOuterRadiusOffset={8}
                            arcLabel={(e) => ((e.value / total) * 100).toFixed(1) + "%"}
                            arcLinkLabel={(e) => (e.value > 100000 ? "$" + millionsToString(e.value) + "M" : "$" + thousandsToString(e.value) + "K")}
                            borderWidth={1}
                            colors={communityGivingPieChartColors}
                            tooltip={(point) => {
                                return (
                                    <Box
                                        sx={{
                                            color: "#333333",
                                            pt: 1,
                                            pb: 1,
                                            backgroundColor: "white",
                                            border: "solid 1px #DDD",
                                        }}
                                    >
                                        <Typography ml={1} variant="caption">
                                            {point.datum.label}:
                                        </Typography>
                                        <Typography mr={1} variant="caption">
                                            {point.datum.value > 100000
                                                ? `$${millionsToString(point.datum.value)} Million`
                                                : `$${thousandsToString(point.datum.value)} Thousand`}
                                        </Typography>
                                    </Box>
                                );
                            }}
                            enableArcLinkLabels={true}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: "color" }}
                            arcLabelsSkipAngle={18}
                            arcLabelsTextColor={getForegroundColor}
                        />
                    </Box>
                ) : (
                    <Typography py={15}>No Available Data</Typography>
                )}
                <Box width="100%" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-around" flexWrap="wrap">
                    <Box>
                        {chartData.legends.map(
                            (k, i) =>
                                i <= 2 && (
                                    <Stack key={i} direction="row" width="100%" my={2}>
                                        <Box width="12px" height="12px" sx={{ backgroundColor: communityGivingPieChartColors[i], mr: "0.3rem" }} />
                                        <Typography mr={2} variant="caption">
                                            {k}
                                        </Typography>
                                    </Stack>
                                ),
                        )}
                    </Box>
                    <Box>
                        {chartData.legends.map(
                            (k, i) =>
                                i > 2 &&
                                i < 6 && (
                                    <Stack key={i} direction="row" width="100%" my={2}>
                                        <Box width="12px" height="12px" sx={{ backgroundColor: communityGivingPieChartColors[i], mr: "0.3rem" }} />
                                        <Typography mr={2} variant="caption">
                                            {k}
                                        </Typography>
                                    </Stack>
                                ),
                        )}
                    </Box>
                    <Box>
                        {chartData.legends.map(
                            (k, i) =>
                                i >= 6 &&
                                i < 9 && (
                                    <Stack key={i} direction="row" width="100%" my={2}>
                                        <Box width="12px" height="12px" sx={{ backgroundColor: communityGivingPieChartColors[i], mr: "0.3rem" }} />
                                        <Typography mr={2} variant="caption">
                                            {k}
                                        </Typography>
                                    </Stack>
                                ),
                        )}
                    </Box>
                    <Box>
                        {chartData.legends.map(
                            (k, i) =>
                                i >= 9 &&
                                i < 12 && (
                                    <Stack key={i} direction="row" width="100%" my={2}>
                                        <Box width="12px" height="12px" sx={{ backgroundColor: communityGivingPieChartColors[i], mr: "0.3rem" }} />
                                        <Typography mr={2} variant="caption">
                                            {k}
                                        </Typography>
                                    </Stack>
                                ),
                        )}
                    </Box>
                    <Box>
                        {chartData.legends.map(
                            (k, i) =>
                                i >= 12 && (
                                    <Stack key={i} direction="row" width="100%" my={2}>
                                        <Box width="12px" height="12px" sx={{ backgroundColor: communityGivingPieChartColors[i], mr: "0.3rem" }} />
                                        <Typography mr={2} variant="caption">
                                            {k}
                                        </Typography>
                                    </Stack>
                                ),
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
