import { Autocomplete, Button, CircularProgress, List, ListItem, ListItemButton, ListItemText, TextField, Tooltip, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext, useEffect, useState } from "react";
import { SimpleDialog } from "./SimpleDialog";
import { useLocation } from "react-router-dom";
import {
    checkConsistency,
    deleteExistingImplanFileData,
    deleteExistingMasterDataSet,
    getFileData,
    getMasterDataSets,
    linkMasterDatasetToImplanDataSet,
    unlinkImplanFileToImplanDataSet,
    unlinkMasterDatasetToImplanDataSet,
} from "../api/implanServices";
import useAlert from "../hooks/useAlert";
import { linkImplanFileToImplanDataSet } from "../api/implanServices";
import ImplanContext from "../context/ImplanContext";

export const MuiListItem = (props: any) => {
    const { list, onClick, masterValidated, setMasterValidated } = props;
    const { implanDataSetC } = useContext(ImplanContext);
    const [consistent, setConsistent] = useState<boolean | null>(null);
    const [validating, setValidating] = useState<boolean>(false);

    const { setAlert } = useAlert();
    const [openDialog, setOpenDialog] = useState(false);
    const [value, setValue] = useState({ name: "", id: "" });
    const [masterDataSetId, setMasterDataSetId] = useState();
    const [existingDataOptions, setExistingDataOptions] = useState([]);
    const location = useLocation();
    const isPathCreate = location.pathname.includes("create");
    const [fileError, setFileError] = useState([]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    const sectionStyle = list.type === "masterdata" || list.type === "STATE_DISTRICT_POPULATION_DATA" ? 4 : 0;

    useEffect(() => {
        if (list.type === "masterdata" && implanDataSetC && implanDataSetC.implanFiles) {
            getMasterDataSets().then((masterdataList: any) => setExistingDataOptions(masterdataList));
            if (implanDataSetC.masterDataSetId && implanDataSetC.masterDataSetName) {
                setValue({ name: implanDataSetC.masterDataSetName, id: implanDataSetC.masterDataSetId.toString() });
            }
        } else if (masterValidated && implanDataSetC && list.type !== "masterdata") {
            getFileData(list.type).then((data) => setExistingDataOptions(data));
            // @ts-ignore
            if (implanDataSetC.implanFiles && implanDataSetC.implanFiles[list.type]) {
                // @ts-ignore
                setValue({ name: implanDataSetC.implanFiles[list.type].name, id: implanDataSetC.implanFiles[list.type].id });
                // @ts-ignore
                const consistent = implanDataSetC.implanFiles[list.type]?.consistency?.consistent;
                if (consistent != null) {
                    setConsistent(consistent);
                }
            }
        }
    }, [list.type, implanDataSetC, masterValidated]);

    const handleExistingProject = (event: any, newValue: any) => {
        setValue(newValue);

        if (list.type === "masterdata") {
            if (newValue === null && implanDataSetC) {
                unlinkMasterDatasetToImplanDataSet(implanDataSetC.id)
                    .then((res) => {
                        res && setAlert("MasterData successfully unlinked from implan data", "success");
                        setMasterValidated(false);
                    })
                    .catch((err) => console.log(err));
            }
            if (newValue !== null && implanDataSetC) {
                linkMasterDatasetToImplanDataSet(implanDataSetC.id, newValue.id)
                    .then((res) => {
                        if (res) {
                            setMasterValidated(true);
                            setAlert("MasterData successfully linked to implan data", "success");
                        }
                    })
                    .catch((err) => console.log(err));
            }
        } else {
            if (newValue === null && implanDataSetC && list.type) {
                unlinkImplanFileToImplanDataSet(implanDataSetC.id, list.type)
                    .then((res) => {
                        if (res) {
                            setAlert(`${list.type} successfully unlinked from implan data`, "success");
                            setConsistent(null);
                        }
                    })
                    .catch((err) => console.log(err));
            }
            if (newValue !== null && implanDataSetC) {
                linkImplanFileToImplanDataSet(implanDataSetC.id, newValue.id)
                    .then((res) => {
                        if (res) {
                            setAlert(`${list.type} successfully linked to implan data`, "success");
                            setConsistent(null);
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    };

    return (
        implanDataSetC && (
            <ListItem disablePadding sx={{ mb: sectionStyle }} onClick={onClick}>
                <ListItemButton sx={{ border: "solid 1px #ddd" }}>
                    <ListItemText id="1" primary={list.name} />
                    <>
                        {consistent !== true && (
                            <Button
                                disabled={isPathCreate || (list.type !== "masterdata" && !masterValidated)}
                                sx={{ ml: "1rem" }}
                                variant="contained"
                                onClick={handleOpenDialog}
                            >
                                Upload
                            </Button>
                        )}
                        {consistent === false && <CloseIcon color="error" />}
                        {consistent === true && <CheckIcon color="success" />}

                        <Autocomplete
                            disabled={isPathCreate || (list.type !== "masterdata" && !masterValidated)}
                            sx={{ width: "50%", ml: "1rem" }}
                            options={existingDataOptions}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            size="small"
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        {option.name}
                                        <Tooltip title="Delete Data">
                                            <DeleteIcon
                                                sx={{ ml: "auto" }}
                                                color="error"
                                                onClick={() => {
                                                    //Delete master data existing files from option
                                                    if (list.type === "masterdata" && implanDataSetC.id) {
                                                        deleteExistingMasterDataSet(implanDataSetC.id, option.id).then((res) => console.log(res));
                                                    }
                                                    //Delete implan data existing files from option
                                                    else {
                                                        deleteExistingImplanFileData(implanDataSetC.id, option.id).then((res) => console.log(res));
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                    </li>
                                );
                            }}
                            id="controlled-demo"
                            value={value}
                            onChange={handleExistingProject}
                            renderInput={(params) => <TextField {...params} label="Add current valid data" />}
                        />
                        {list.type !== "masterdata" && (
                            <Button
                                variant="outlined"
                                sx={{ ml: 2 }}
                                disabled={isPathCreate || (list.type !== "masterdata" && !masterValidated) || validating}
                                onClick={() => {
                                    if (implanDataSetC.id) {
                                        if (value.id === "") {
                                            setAlert("Please select an existing file or upload a new file to validate", "warning");
                                        } else {
                                            setValidating(true);
                                            checkConsistency(implanDataSetC.id, value.id).then((res) => {
                                                setValidating(false);
                                                setConsistent(res.consistency.consistent);
                                                if (res.consistency.consistent === false) {
                                                    setFileError(res.consistency.errors);
                                                } else {
                                                    setFileError([]);
                                                }
                                            });
                                        }
                                    }
                                }}
                            >
                                Validate{validating && <CircularProgress size={16} />}
                            </Button>
                        )}
                        {fileError && (
                            <List>
                                {fileError.map((error: string) => (
                                    <ListItem>
                                        <Typography display="block" sx={{ width: "100%" }} color="error" variant="caption">
                                            {error}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        <SimpleDialog
                            openDialog={openDialog}
                            implanData={list}
                            handleClose={handleClose}
                            setMasterValidated={setMasterValidated}
                            setMasterDataSetId={setMasterDataSetId}
                            masterDataSetId={masterDataSetId}
                        />
                    </>
                </ListItemButton>
            </ListItem>
        )
    );
};
