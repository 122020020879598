import { Box, Stack, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { CardItem } from "./CardItem";
import { defaultNumberFormatter } from "../utils/numberDisplay";
import { EmploymentImpactType } from "../types/dashboard";
import { cardSettings } from "../widgets/constants";

export const EmploymentImpact = ({ directJobs, indirectInducedJobs, indirectInducedJobsRatio, getSelectedStateAndDistrictName }: EmploymentImpactType) => {
    const employmentImpact = {
        production: { description: "Direct jobs in the Coca-Cola system supported", value: defaultNumberFormatter(directJobs) },
        physical: { description: "Indirect & Induced jobs supported", value: defaultNumberFormatter(indirectInducedJobs) },
        consumer: {
            description: `For every 1 direct job in the Coca-Cola system, ${indirectInducedJobsRatio} indirect and induced jobs are supported in ${getSelectedStateAndDistrictName()}`,
            value: `1:${defaultNumberFormatter(indirectInducedJobsRatio)}`,
        },
    };
    return (
        <Box
            p={1}
            minHeight={235}
            sx={{
                borderRadius: cardSettings.smallCardBorderRadius,
                border: cardSettings.border,
                boxShadow: cardSettings.boxShadow,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography variant="h6" component="div" m={1}>
                    Employment Impact
                </Typography>
                <PeopleIcon color="primary" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" alignItems="top" flexWrap="wrap" p={1} ml={1.5}>
                <CardItem styling="33%" value={employmentImpact.production.value} description={employmentImpact.production.description} />
                <CardItem styling="32%" value={employmentImpact.physical.value} description={employmentImpact.physical.description} />
                <CardItem styling="33%" value={employmentImpact.consumer.value} description={employmentImpact.consumer.description} />
            </Stack>
        </Box>
    );
};
