import { useState } from "react";
import { FetchOptions, useFetch } from "../hooks/useFetch";
import { API_URI } from "../utils/urls";
import { Results } from "../types/dashboard";

export function useResults(
    options: FetchOptions
): [Results | null, (results: Results | null) => void, (scenarioId: string, stateIds: Array<string>, districtIds: Array<string>) => void] {
    const [results, setResults] = useState<Results | null>(null);
    const { post } = useFetch(`${API_URI}/dashboard/results`, setResults, options);
    return [
        results,
        setResults,
        (scenarioId: string, stateIds: Array<string>, districtIds: Array<string>) =>
            post({
                scenarioId: scenarioId,
                stateIds: stateIds,
                districtIds: districtIds,
            }),
    ];
}

export async function getStates(scenarioId: string) {
    const response = await fetch(`${API_URI}/dashboard/scenario/${scenarioId}/states/list`);
    return await response.json();
}

export async function getDistricts(stateId: string) {
    const response = await fetch(`${API_URI}/dashboard/state/${stateId}/districts/list`);
    return await response.json();
}
