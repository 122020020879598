import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, ListItem, ListItemButton, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Paths from "../../Paths";
import { ScenarioData, deleteScenario, useScenarios } from "../../api/scenarioServices";
import useAlert from "../../hooks/useAlert";
import { ConfirmationDialog } from "../ConfirmationDialog";

type Props = { scenario: ScenarioData };

export const ScenarioListItem = ({ scenario }: Props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [, getAllScenarios] = useScenarios();
    const { setAlert } = useAlert();

    const handleClose = () => {
        setOpenDialog(false);
    };

    const published = scenario.status === "PUBLISHED";
    return (
        <ListItem disablePadding>
            <ListItemButton sx={{ border: "solid 1px #ddd" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
                    <ListItemButton to={`${Paths.scenarios.path}/${scenario.id}`} component={RouterLink}>
                        <ListItemText id="2" primary={scenario.name} />
                    </ListItemButton>
                    <Tooltip title="Scenario Status">
                        <Typography>{scenario.status}</Typography>
                    </Tooltip>
                    <Tooltip title="Delete Scenario">
                        <IconButton
                            disabled={published}
                            onClick={() => {
                                setOpenDialog(true);
                            }}
                            color="error"
                        >
                            <DeleteIcon sx={{ ml: 6 }} />
                        </IconButton>
                    </Tooltip>
                    <ConfirmationDialog
                        handleClose={handleClose}
                        openDialog={openDialog}
                        questionText={`Are you sure you want to delete the scenario '${scenario.name}'?`}
                        confirmationText="Delete"
                        confirmationAction={() => {
                            deleteScenario(scenario.id).then((res) => {
                                console.log(res.error);
                                if (res.error) {
                                    setAlert(res.error, "error");
                                    handleClose();
                                } else {
                                    getAllScenarios();
                                    setAlert("Scenario successfully deleted.", "success");
                                    handleClose();
                                }
                            });
                        }}
                    />
                </Stack>
            </ListItemButton>
        </ListItem>
    );
};
