import { BarChart } from "../charts/BarChart";
import { employmentChartByValueColors } from "../charts/colors";
import { EmploymentType } from "../types/dashboard";

type Props = {
    employmentChartData: EmploymentType;
    animate?: boolean;
    title?: boolean;
};

export const EmploymentByValue = ({ employmentChartData, animate, title }: Props) => {
    const convertedEmploymentByValue = [
        {
            type: "Upstream",
            "Direct suppliers": employmentChartData.byValue.upstream.direct,
            "Suppliers' suppliers": employmentChartData.byValue.upstream.indirect,
            "Upstream induced": employmentChartData.byValue.upstream.induced,
            total: employmentChartData.byValue.upstream.total,
        },
        {
            type: "The Coca-Cola system",
            "The Coca-Cola system": employmentChartData.system.headcount,
            total: employmentChartData.system.headcount,
        },
        {
            type: "Downstream",
            "Direct trade outlets": employmentChartData.byValue.downstream.direct,
            "Trade outlets' suppliers": employmentChartData.byValue.downstream.indirect,
            "Downstream induced": employmentChartData.byValue.downstream.induced,
            total: employmentChartData.byValue.downstream.total,
        },
    ];

    return (
        <BarChart
            data={convertedEmploymentByValue}
            indexBy="type"
            keys={[
                "Direct suppliers",
                "Suppliers' suppliers",
                "Upstream induced",
                "The Coca-Cola system",
                "Direct trade outlets",
                "Trade outlets' suppliers",
                "Downstream induced",
            ]}
            colors={employmentChartByValueColors}
            isSystemCleared={true}
            title="Employment supported by level "
            boldSpan="(# of people)"
            renderTitle={title}
            animate={animate}
            legendTotalInOneColumn={3}
        />
    );
};
