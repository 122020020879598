import { Box, Stack, Typography } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import { CardItem } from "./CardItem";
import { dollarsToString } from "../utils/numberDisplay";
import { EconomicImpactType } from "../types/dashboard";
import { cardSettings } from "../widgets/constants";

export const EconomicImpact = ({ valueAdded, gdpSupported, totalTaxRevenue, getSelectedStateAndDistrictName }: EconomicImpactType) => {
    const economicImpact = {
        production: {
            description: `Value added supported in ${getSelectedStateAndDistrictName()}`,
            value: dollarsToString(valueAdded),
        },
        physical: { description: "GDP supported", value: `${parseFloat(gdpSupported).toFixed(2)}%` },
        consumer: {
            description: `Total tax revenues supported in ${getSelectedStateAndDistrictName()} `,
            value: dollarsToString(totalTaxRevenue),
        },
    };
    return (
        <Box
            minHeight={235}
            p={1}
            sx={{
                borderRadius: cardSettings.smallCardBorderRadius,
                border: cardSettings.border,
                boxShadow: cardSettings.boxShadow,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography gutterBottom variant="h6" m={1}>
                    Economic Impact
                </Typography>
                <PaymentsIcon color="primary" />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" alignItems="top" flexWrap="wrap" p={1} ml={1.5}>
                <CardItem styling="32%" value={economicImpact.production.value} description={economicImpact.production.description} />
                <CardItem styling="33%" value={economicImpact.physical.value} description={economicImpact.physical.description} />
                <CardItem styling="33%" value={economicImpact.consumer.value} description={economicImpact.consumer.description} />
            </Stack>
        </Box>
    );
};
