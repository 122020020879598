import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { exportify, imageSizes } from "../components/ExportPdf";

import "./dashboard.css";

import DashboardContext from "../context/DashboardContext";
import { EmploymentByValue } from "../components/EmploymentByValue";
import { ValueAddedByChainLink } from "../components/ValueAddedByChainLink";
import { PieChartPDF } from "../charts/PieChartPDF";
import { GenerateGridWithIcons } from "../widgets/GenerateGridWithIcons";
import { Results } from "../types/dashboard";
import { MapPdf } from "../charts/map/MapPdf";

type Props = {
    result: Results;
    onPdfDownloaded: () => void;
};

export const DashboardPdf = ({ result, onPdfDownloaded }: Props) => {
    const { getDashboardScenarioName, getSelectedState, stateNames, district } = useContext(DashboardContext);
    const scenarioName = getDashboardScenarioName();

    const selectedStateData = getSelectedState();
    const scrollRef = useRef(null);
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;

            setTimeout(() => {
                if (result) {
                    const jobs = result
                        ? result.employment.byValue.downstream.total + result.employment.byValue.upstream.total + result.employment.system.headcount
                        : null;

                    const cocaColaSystemSupports =
                        result.valueAdded.byValueChainLink.downstream.total +
                        result.valueAdded.byValueChainLink.upstream.total +
                        result.valueAdded.system.total;

                    exportify(selectedStateData?.name || "United States", getDashboardScenarioName, jobs, cocaColaSystemSupports, result, district).then(() => {
                        onPdfDownloaded();
                    });
                }
            }, 1000);
        }
    }, [getDashboardScenarioName, onPdfDownloaded, result, selectedStateData?.name, district]);

    useEffect(() => {
        // Make sure we center on the loading spinner
        //@ts-ignore
        scrollRef.current && scrollRef.current.scrollIntoView({ behavior: "instant" });
    }, []);

    return (
        <Box>
            <Box ref={scrollRef} width="100vw" height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="h4"> Creating PDF...</Typography>
                <CircularProgress sx={{ height: "500px", width: "500px" }} />
            </Box>
            <Typography
                variant="h4"
                id="header"
                component="span"
                sx={{
                    span: { color: "red" },
                    fontWeight: 600,
                }}
            >
                The Economic Impact of The Coca-Cola System in <span>{scenarioName ? scenarioName.name : ""}</span>
            </Typography>
            <Typography id="headerDescription" mt={3} variant="subtitle1">
                The Coca-Cola System, comprising the Coca-Cola Company and its independent bottling partners, supports economies by producing, distributing, and
                selling beverages. Its activities generate significant direct, indirect, and induced impacts on national, state, and local economies by
                supporting employment and contributing to Gross Domestic Product. Below you can find an overview of its direct and indirect impact on
                employment, value added, and the community it operates in.
            </Typography>
            <MapPdf stateNames={stateNames} />
            <Grid container item direction="column" lg={9} md={11} xs={12} m="auto">
                {result && (
                    <>
                        <GenerateGridWithIcons employmentNumber={result.summary.employmentImpact.indirectInducedJobsRatio} scale={imageSizes.scale} />
                        <Box
                            width={imageSizes.employment.width * imageSizes.scale}
                            height={imageSizes.employment.height * imageSizes.scale}
                            id="valueAddedByChainLink"
                        >
                            <ValueAddedByChainLink valueAddedChartData={result.valueAdded} animate={false} title={false} />
                        </Box>
                        <Box
                            width={imageSizes.employment.width * imageSizes.scale}
                            height={imageSizes.employment.height * imageSizes.scale}
                            id="exportEmployment"
                        >
                            <EmploymentByValue employmentChartData={result.employment} animate={false} title={false} />
                        </Box>
                        <Box
                            width={imageSizes.communityGiving.width * imageSizes.scale}
                            height={imageSizes.communityGiving.height * imageSizes.scale}
                            id="communityGivingChart"
                        >
                            <PieChartPDF data={result.communityGiving} />
                        </Box>
                    </>
                )}
            </Grid>
        </Box>
    );
};
