export const getChartBoxStyling = (height: number) => {
    return {
        width: "49%",
        height: height,
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
        mt: 3,
        mb: 4,
    };
};
export const chartTitleStyling = {
    span: { fontWeight: 600 },
    mb: 4,
};
export const barChartBoxStyling = {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    pb: 9,
};
