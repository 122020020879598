import {
    Alert,
    Autocomplete,
    Breadcrumbs,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Paths from "../Paths";
import CreateBottlerForm from "./CreateBottlerForm";
import AlertDialog from "../widgets/AlertDialog";
import { Bottler, UserContext } from "../context/UserContext";
import { deleteBottler } from "../api/userServices";
import useAlert from "../hooks/useAlert";

export type BottlerForm = {
    name?: string;
};

function Bottlers() {
    const { bottlerList } = useContext(UserContext);
    const [isOpen, setIsOpen] = useState(false);
    const [editFormId, setEditFormId] = useState<undefined | number>(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredBottlerLists, setFilteredBottlerLists] = useState<Array<any>>([]);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [value, setValue] = useState(null);
    const { setAlert } = useAlert();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDeleteBottler = async (bottlerId: number) => {
        deleteBottler(bottlerId).then(async (response) => {
            if (response.ok) {
                setAlert("User successfully deleted", "success");
            } else {
                const errorMessage = await response.text();
                setAlert(errorMessage, "error");
            }
        });
    };
    const columns = ["Id", "Name", ""];

    useEffect(() => {
        setFilteredBottlerLists(bottlerList);
    }, [bottlerList]);

    return (
        <main id="muiItemsContainer">
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={Paths.implan.path}>
                    Admin
                </Link>
                <Typography color="text.primary">Bottlers</Typography>
            </Breadcrumbs>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Autocomplete
                    sx={{ minWidth: "75%" }}
                    options={bottlerList}
                    getOptionLabel={(option: any) => option.name}
                    value={value}
                    onChange={(event: any, newValue: any) => {
                        setValue(newValue);
                        newValue !== null ? setFilteredBottlerLists([newValue]) : setFilteredBottlerLists(bottlerList);
                    }}
                    renderInput={(params) => <TextField {...params} label="Search bottler" variant="standard" />}
                />
                <Tooltip title=" Create New Bottler" followCursor>
                    <Button variant="outlined" size="small" onClick={() => setIsOpen(true)}>
                        Create New Bottler
                    </Button>
                </Tooltip>
            </Stack>
            <Box sx={{ mt: 1 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ textAlign: "left" }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "#F40009" }}>
                                {columns.map((c, i) => (
                                    <TableCell key={i} sx={{ fontWeight: "600", color: "white" }}>
                                        {c}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredBottlerLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: Bottler) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        transition: "ease all .5s",
                                        "&:hover": { backgroundColor: "#F5F5F5" },
                                    }}
                                >
                                    <TableCell align="left">{row.id}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>

                                    <TableCell align="right">
                                        <Tooltip title="Delete Bottler" followCursor>
                                            <DeleteIcon
                                                onClick={() => {
                                                    setNotificationOpen(true);
                                                    setEditFormId(row.id);
                                                }}
                                                sx={{ color: "red", cursor: "pointer" }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={bottlerList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
                {message.length > 0 && (
                    <Alert severity={message.includes("Successfully") ? "success" : "error"} onClose={() => setMessage("")}>
                        {message}
                    </Alert>
                )}
            </Box>
            <AlertDialog
                isOpen={notificationOpen}
                setIsOpen={setNotificationOpen}
                modalTitle=" Are you sure you want to delete bottler ?"
                modalContent=" Selected bottler will be deleted."
                handleAgree={() => {
                    editFormId && handleDeleteBottler(editFormId);
                    setNotificationOpen(false);
                }}
            />
            {isOpen && <CreateBottlerForm isOpen={isOpen} setIsOpen={setIsOpen} setMessage={setMessage} />}
        </main>
    );
}

export default Bottlers;
