import {
    Autocomplete,
    Breadcrumbs,
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import * as Paths from "../Paths";
import AlertDialog from "../widgets/AlertDialog";
import { User, UserContext } from "../context/UserContext";
import { EditUserForm } from "./EditUserForm";
import CreateUserForm from "./CreateUserForm";
import { useDeleteUser } from "../api/userServices";
import { API_URI } from "../utils/urls";
import useAlert from "../hooks/useAlert";

export type UserInfo = {
    name: string;
    email: string;
    disabled: boolean;
    userRole: "Admin" | "CocaColaCompany" | "Bottler";
    bottlerId: number;
    bottlerName: string;
};

function Users() {
    const { userList } = useContext(UserContext);
    const [isOpen, setIsOpen] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [editFormId, setEditFormId] = useState<undefined | number>(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [form, setForm] = useState<UserInfo | {}>({});
    const [filteredUsersList, setFilteredUsersList] = useState<Array<User>>([]);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [value, setValue] = useState(null);

    const handleDeleteUser = useDeleteUser(editFormId, { errorMessage: "Error deleting user", successMessage: "User successfully deleted" });

    const { setAlert } = useAlert();
    const sendWelcomeEmail = (id: number) => {
        fetch(`${API_URI}/users/${id}/sendWelcomeMail`, { method: "POST" })
            .then((r) => {
                if (r.ok) {
                    setAlert("Welcome mail sent", "success");
                } else {
                    return Promise.reject();
                }
            })
            .catch(() => {
                setAlert("Sending welcome mail failed", "error");
            });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleUserTableClick = (row: User) => {
        setOpenEditForm(true);
        setEditFormId(row.id);
        setForm({
            name: row.name,
            email: row.email,
            disabled: row.disabled,
            userRole: row.userRole,
            bottlerId: row.bottlerId,
            bottlerName: row.bottlerName,
        });
    };

    const columns = ["Id", "Name", "Email", "Status", "Role", "Bottler", ""];

    useEffect(() => {
        setFilteredUsersList(userList);
    }, [userList]);

    return (
        <main id="muiItemsContainer">
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={Paths.implan.path}>
                    Admin
                </Link>
                <Typography color="text.primary">User Management</Typography>
            </Breadcrumbs>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Autocomplete
                    sx={{ minWidth: "75%" }}
                    options={userList}
                    getOptionLabel={(option: any) => option.name}
                    value={value}
                    onChange={(event: any, newValue: any) => {
                        setValue(newValue);
                        newValue !== null ? setFilteredUsersList([newValue]) : setFilteredUsersList(userList);
                    }}
                    renderInput={(params) => <TextField {...params} label="Search user" variant="standard" />}
                />
                <Tooltip title="Create New User" followCursor>
                    <Button variant="outlined" size="small" onClick={() => setIsOpen(true)}>
                        Create New User
                    </Button>
                </Tooltip>
            </Stack>
            <Box sx={{ mt: 1 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ textAlign: "left" }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "#F40009" }}>
                                {columns.map((c, i) => (
                                    <TableCell key={i} sx={{ fontWeight: "600", color: "white" }}>
                                        {c}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsersList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: User) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        transition: "ease all .5s",
                                        "&:hover": { backgroundColor: "#F5F5F5" },
                                    }}
                                >
                                    {openEditForm && row.id === editFormId && (
                                        <EditUserForm setForm={setForm} form={form} setOpenEditForm={setOpenEditForm} editFormId={editFormId} />
                                    )}
                                    {!openEditForm && (
                                        <>
                                            <TableCell sx={{ cursor: "pointer" }} align="left" onClick={() => handleUserTableClick(row)}>
                                                {row.id}
                                            </TableCell>
                                            <TableCell sx={{ cursor: "pointer" }} align="left" onClick={() => handleUserTableClick(row)}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={{ cursor: "pointer" }} align="left" onClick={() => handleUserTableClick(row)}>
                                                {row.email}
                                            </TableCell>
                                            <TableCell sx={{ cursor: "pointer" }} align="left" onClick={() => handleUserTableClick(row)}>
                                                {row.disabled ? "Inactive" : "Active"}
                                            </TableCell>
                                            <TableCell sx={{ cursor: "pointer" }} align="left" onClick={() => handleUserTableClick(row)}>
                                                {row.userRole}
                                            </TableCell>
                                            <TableCell sx={{ cursor: "pointer" }} align="left" onClick={() => handleUserTableClick(row)}>
                                                {row.bottlerName}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Stack direction="row">
                                                    <Tooltip title="Resend welcome mail" followCursor>
                                                        <IconButton size="small" onClick={() => sendWelcomeEmail(row.id)}>
                                                            <MailIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete User" followCursor>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                setNotificationOpen(true);
                                                                setEditFormId(row.id);
                                                            }}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={userList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
            <AlertDialog
                isOpen={notificationOpen}
                setIsOpen={setNotificationOpen}
                modalTitle=" Are you sure you want to delete user ?"
                modalContent=" Selected user will be deleted."
                handleAgree={() => {
                    handleDeleteUser();
                    setNotificationOpen(false);
                }}
            />
            {isOpen && <CreateUserForm isOpen={isOpen} setIsOpen={setIsOpen} />}
        </main>
    );
}

export default Users;
