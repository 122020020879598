import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useAlert from "../hooks/useAlert";
import { createMasterDataSetFiles } from "../api/implanServices";
import { useParams } from "react-router-dom";
import ImplanContext from "../context/ImplanContext";
import { SingleFileUpload } from "./SingleFileUpload";

export interface SimpleDialogProps {
    openDialog: boolean;
    implanData: any;
    handleClose: () => void;
    setMasterValidated: any,
    setMasterDataSetId?: any,
    masterDataSetId?: string,
    parentFormData?: any,
}

export function SimpleDialog(props: SimpleDialogProps) {

    const { handleClose, implanData, openDialog, setMasterValidated, setMasterDataSetId } = props;

    const { setAlert } = useAlert();
    const { id } = useParams();
    const implanDataSetId = id;

    const [name, setName] = useState("")
    const [stateFile, setStateFile] = useState()
    const [districtsFile, setDistrictsFile] = useState()
    const [industriesFile, setIndustriesFile] = useState()
    const [commoditiesFile, setCommoditiesFile] = useState()
    const [allFilesValid, setAllFilesValid] = useState(false)

    const { getImplanDataset } = useContext(ImplanContext);

    useEffect(() => {
        if (stateFile && districtsFile && industriesFile && commoditiesFile) {
            setAllFilesValid(true);
        }
    }, [stateFile, districtsFile, industriesFile, commoditiesFile])

    const masterDataFileUploads = [
        { name: "State File", type: "states" },
        { name: "District File", type: "districts" },
        { name: "Industries File", type: "industries" },
        { name: "Commodities File", type: "commodities" },
    ]

    const handleOnValidate = (type: string, file: any) => {
        switch (type) {
            case "states": setStateFile(file)
                break;
            case "districts": setDistrictsFile(file)
                break;
            case "industries": setIndustriesFile(file)
                break;
            case "commodities": setCommoditiesFile(file)
                break;
        }
    }

    return (
        <Dialog onClose={handleClose} open={openDialog} fullWidth>
            <DialogTitle>{implanData.name}</DialogTitle>
            <Divider />
            {implanData.type === "masterdata" && <Box m={4}>
                <TextField
                    sx={{ width: "100%" }}
                    id={implanData.type}
                    size="small"
                    name="masterDataSetName"
                    label="Add a name for master data"
                    onChange={(e) => setName(e.target.value)}
                />
                {masterDataFileUploads.map((mdFile: any, i) => {
                    return <SingleFileUpload
                        shouldValidate={true}
                        mdFile={mdFile}
                        key={i}
                        stateFile={stateFile}
                        onValidated={(e) => handleOnValidate(mdFile.type, e)} />
                })}
            </Box>}
            {implanData.type !== "masterdata" && <Box m={4}>
                <TextField
                    sx={{ width: "100%" }}
                    id={implanData.type}
                    size="small"
                    name="fileName"
                    label="Add a name to file"
                    onChange={(e) => setName(e.target.value)}
                />
                {/* Use shouldValidate props to be sure if masterdata or implandata uploaded */}
                {implanDataSetId && <SingleFileUpload
                    mdFile={implanData}
                    shouldValidate={false}
                    stateFile={stateFile}
                    onValidated={(e) => handleOnValidate(implanData.type, e)}
                    fileName={name}
                    handleClose={handleClose} />}

            </Box>}
            <Divider />
            <DialogActions>

                {implanData.type === "masterdata" && <Button disabled={(!allFilesValid || name === "")} onClick={() => {
                    if (name && stateFile && districtsFile && commoditiesFile && industriesFile) {
                        const masterData = new FormData()
                        masterData.append("masterDataSetName", name)
                        masterData.append("states", stateFile)
                        masterData.append("districts", districtsFile)
                        masterData.append("commodities", commoditiesFile)
                        masterData.append("industries", industriesFile)

                        id && createMasterDataSetFiles(id, masterData).then(res => {
                            if (res) {
                                setAlert('MasterData successfully created', 'success')
                                setMasterDataSetId(res.masterDataSetId)
                                getImplanDataset()
                                handleClose()
                            }
                        }).catch(() => setAlert('Failed to save masterdata', 'error'))
                        setMasterValidated(true)
                    }
                }}>
                    Save
                </Button>}
            </DialogActions>
        </Dialog>
    );
}
