import { Box } from "@mui/material";
import "./MapPdf.css";
import { UsStates } from "./UsStates";

export type MapProps = {
    stateNames: any;
};

export const MapPdf = ({ stateNames }: MapProps) => {
    return (
        <Box height={600} width={959} m="auto" id="map" className="mapPdf">
            <UsStates stateNames={stateNames} />
        </Box>
    );
};
