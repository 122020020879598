import { Grid, Slider, TextField, Typography } from "@mui/material";
import { EmploymentByValue } from "../components/EmploymentByValue";
import { EmploymentImpact } from "../components/EmploymentImpact";
import { GenerateGridWithIcons } from "../widgets/GenerateGridWithIcons";
import { useState } from "react";
const employmentChartData = {
    byValue: {
        upstream: {
            total: 60,
            indirect: 30,
            induced: 20,
            direct: 10,
        },
        downstream: {
            total: 60,
            indirect: 30,
            induced: 20,
            direct: 10,
        },
    },
    bySector: [
        {
            sector: "a",
            total: 60,
            indirect: 30,
            induced: 20,
            direct: 10,
        },
    ],
    system: {
        headcount: 123,
    },
};

export const Widgets = () => {
    const [employmentGridScale, setEmploymentGridScale] = useState(2);
    const [employmentRatio, setEmploymentRatio] = useState(12);

    const [stateAndDistrictName, setStateAndDistrictName] = useState("state and/or district name");

    return (
        <Grid sx={{ m: 20 }}>
            <Typography variant="h1">Widgets test page</Typography>
            {/*  */}
            <Typography variant="h2">Employment PDF chart</Typography>
            Scale: '{employmentGridScale}''
            <Slider marks={true} min={1} max={10} defaultValue={employmentGridScale} onChange={(e, value) => setEmploymentGridScale(value as number)} />
            Employment ratio: '{employmentRatio}'
            <Slider min={1} max={50} defaultValue={employmentRatio} onChange={(_e, value) => setEmploymentRatio(value as number)} />
            <GenerateGridWithIcons employmentNumber={employmentRatio} scale={employmentGridScale} />
            {/*  */}
            <Typography variant="h2">Employment impact</Typography>
            <TextField defaultValue={stateAndDistrictName} onChange={(e) => setStateAndDistrictName(e.target.value)} />
            <EmploymentImpact
                directJobs={10}
                indirectInducedJobs={20}
                indirectInducedJobsRatio={2}
                getSelectedStateAndDistrictName={() => stateAndDistrictName}
            />
            {/*  */}
            <Typography variant="h2">Employment by value</Typography>
            <EmploymentByValue employmentChartData={employmentChartData} />
        </Grid>
    );
};
