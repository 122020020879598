import { Autocomplete, Breadcrumbs, Button, Chip, Divider, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import * as Paths from "../Paths";
import { useEffect, useMemo, useState } from "react";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import ClearIcon from "@mui/icons-material/Clear";

import {
    ScenarioForm,
    getScenario,
    getStoppingMethods,
    linkImplanDataSetToScenario,
    linkStoppingMethodToScenario,
    linkStoppingThresholdToScenario,
    startScenario,
    stopScenario,
    updateScenarioNameAndMaxBottlers,
    usePublishScenario,
    useUnpublishScenario,
} from "../api/scenarioServices";
import useAlert from "../hooks/useAlert";
import ScenarioStates from "../components/scenario/ScenarioStates";
import ScenarioIndustries from "../components/scenario/ScenarioIndustries";
import ScenarioCommodities from "../components/scenario/ScenarioCommodities";
import { ImplanDataSet, getImplanDataSets } from "../api/implanServices";

export type State = {
    id: number;
    name: string;
    checked?: boolean;
};
export type Industry = {
    id: number;
    name: string;
    checked: boolean;
};
export type Commodity = {
    id: number;
    name: string;
    checked: boolean;
};
export type StoppingMethod = {
    id: string;
    name: string;
};
const ScenarioDetail = () => {
    const { id } = useParams();
    const [changed, setChanged] = useState(false);
    const [name, setName] = useState("");
    const [maxBottlersNo, setMaxBottlersNo] = useState<number | string | null>(null);
    const [stoppingMethods, setStoppingMethods] = useState<Array<StoppingMethod>>([]);
    const [stoppingThreshold, setStoppingThreshold] = useState(100);
    const [selectedStoppingMethod, setSelectedStoppingMethod] = useState({});
    const [states, setStates] = useState<Array<State>>([]);
    const [industries, setIndustries] = useState<Array<Industry>>([]);
    const [commodities, setCommodities] = useState<Array<Commodity>>([]);
    const [scenario, setScenario] = useState<ScenarioForm>({});
    const [implanList, setImplanList] = useState<Array<ImplanDataSet>>();

    const { setAlert } = useAlert();

    const updateScenario = () => {
        if (id) {
            getSingleScenario(id);
        }
    };

    const publishScenario = usePublishScenario(id || "", updateScenario, {
        successMessage: "Scenario successfully published.",
        errorMessage: "Error : Scenario wasn't published!",
    });

    const unpublishScenario = useUnpublishScenario(id || "", updateScenario, {
        successMessage: "Scenario successfully unpublished.",
        errorMessage: "Error : Scenario wasn't unpublished!",
    });

    const getSingleScenario = (id: string) => {
        return getScenario(id).then((res) => {
            setName(res.name);
            setMaxBottlersNo(res.maxBottlersNo);
            setSelectedStoppingMethod({ name: res.stoppingMethodName, id: res.stoppingMethodId });
            setStoppingThreshold(res.stoppingThreshold);
            setStates(res.states);
            setIndustries(res.industries);
            setCommodities(res.commodities);
            setScenario(res);
        });
    };

    const selectedDataSet = useMemo(() => {
        return implanList?.find((implanSet) => implanSet.id === scenario.implanDataSetId) || null;
    }, [implanList, scenario.implanDataSetId]);

    useEffect(() => {
        if (id) {
            getSingleScenario(id);
        }
    }, [id]);

    useEffect(() => {
        getStoppingMethods().then((res) =>
            setStoppingMethods([
                { name: res["1"], id: "1" },
                { name: res["2"], id: "2" },
            ])
        );
    }, []);

    useEffect(() => {
        getImplanDataSets().then((implanDataSets) => setImplanList(implanDataSets));
    }, []);

    const handlePublish = () => {
        if (id) {
            if (scenario?.status === "PUBLISHED") {
                unpublishScenario();
            } else {
                publishScenario();
            }
        }
    };

    const handleClearClick = (scenarioId: string) => {
        setMaxBottlersNo("");
        handleNameAndMaxBottlersNoUpdate(scenarioId, { name: name, maxBottlersNo: null });
    };

    const handleNameAndMaxBottlersNoUpdate = (id: string, data: { name: string; maxBottlersNo: number | string | null }) => {
        updateScenarioNameAndMaxBottlers(id, data).then((res) => {
            if (res.error) {
                setAlert(res.error, "error");
            } else if (res.id) {
                setAlert("Scenario name successfully updated.", "success");
            }
        });
    };

    const editable = scenario.status === "NEW";
    const ready = ["FINISHED_SUCCESSFUL", "PUBLISHED"].includes(scenario?.status || "");

    return (
        <>
            {scenario && (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to={Paths.implan.path}>
                        Admin
                    </Link>
                    <Link color="inherit" to={Paths.scenarios.path}>
                        Scenario
                    </Link>
                    <Typography color="text.primary">{name}</Typography>
                </Breadcrumbs>
            )}
            {name && (
                <Stack mt={2} direction="row" justifyContent="space-between">
                    <TextField
                        fullWidth
                        id="scenarioName"
                        size="small"
                        value={name}
                        label="Add a scenario name"
                        onChange={(e) => {
                            setChanged(true);
                            setName(e.target.value);
                        }}
                    />
                    <Button
                        disabled={!changed}
                        variant="outlined"
                        sx={{ ml: 2 }}
                        onClick={() => {
                            scenario.id && handleNameAndMaxBottlersNoUpdate(scenario.id, { name: name, maxBottlersNo: maxBottlersNo });
                            setChanged(false);
                        }}
                    >
                        Update
                    </Button>
                </Stack>
            )}
            <Stack direction="row" justifyContent="space-between" width="100%" mt={2}>
                <Stack flexWrap="wrap" direction="column" justifyContent="space-between" width="20%" mt={1}>
                    <Autocomplete
                        disabled={!editable}
                        disableClearable={!!selectedDataSet}
                        sx={{ width: "100%" }}
                        options={implanList || []}
                        getOptionLabel={(option) => option?.name || ""}
                        id="implanData"
                        value={selectedDataSet}
                        onChange={(_, newDataSet) => {
                            scenario.id &&
                                newDataSet &&
                                linkImplanDataSetToScenario(newDataSet.id, scenario.id).then((res) => {
                                    if (res.name) {
                                        setAlert("Implan DataSet successfully set.", "success");
                                        scenario.id && getSingleScenario(scenario.id);
                                    } else if (res.error) {
                                        setAlert(res.error, "error");
                                    }
                                });
                        }}
                        getOptionDisabled={(option) => option.state !== "Ready" && option.state !== "Used"}
                        renderOption={(props, option) => (
                            <li {...props}>
                                {option.name} <Chip size="small" sx={{ ml: "0.3rem", maxHeight: "20px" }} label={option.state} />
                            </li>
                        )}
                        renderInput={(params) => <TextField {...params} label="Search implan data" />}
                    />
                    <Stack width="100%">
                        <TextField
                            disabled={!editable}
                            id="maxBottlersNo"
                            value={maxBottlersNo}
                            type="number"
                            label="Add maximum bottlers"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9]/g, "");
                                if (numericInput !== "") {
                                    setMaxBottlersNo(numericInput);
                                    scenario.id && handleNameAndMaxBottlersNoUpdate(scenario.id, { name: name, maxBottlersNo: parseInt(numericInput) });
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {maxBottlersNo && (
                                            <IconButton onClick={() => scenario.id && handleClearClick(scenario.id)} edge="end">
                                                <ClearIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Autocomplete
                            disabled={!editable}
                            sx={{ mt: 2 }}
                            disableClearable
                            options={stoppingMethods}
                            getOptionLabel={(option: any) => option?.name || ""}
                            id="stoppingMethod"
                            value={selectedStoppingMethod}
                            onChange={(event: any, newValue: any) => {
                                scenario.id &&
                                    linkStoppingMethodToScenario(newValue.id, scenario.id)
                                        .then((res) => {
                                            if (res.name) {
                                                setAlert("Stopping method successfully set.", "success");
                                            } else if (res.error) {
                                                setAlert(res.error, "error");
                                            }
                                        })
                                        .catch((err) => setAlert(err, "error"));
                                setSelectedStoppingMethod({ name: newValue.name, id: newValue.id });
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Stopping Method" />}
                        />
                        <TextField
                            disabled={!editable}
                            sx={{ mt: 2 }}
                            value={stoppingThreshold}
                            id="stoppingThreshold"
                            label="Stopping Threshold"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                id && linkStoppingThresholdToScenario(parseInt(e.target.value), id);
                                setStoppingThreshold(parseInt(e.target.value));
                            }}
                        />
                        <Typography>State: {scenario.status}</Typography>
                        <Button variant="outlined" disabled={!ready} onClick={handlePublish}>
                            {scenario?.status === "PUBLISHED" ? "Unpublish" : "Publish"}
                        </Button>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" width="80%" sx={{ mt: 1, ml: 2, border: "solid 1px #ddd", p: 3.2 }} height={350}>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ScenarioStates {...{ states, setStates, editable }} />

                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ScenarioIndustries industries={industries} setIndustries={setIndustries} {...{ editable }} />

                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ScenarioCommodities commodities={commodities} setCommodities={setCommodities} {...{ editable }} />
                </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" width="50%" ml="auto" mt={5} px={30}>
                <PlayCircleOutlineIcon
                    color="secondary"
                    sx={{ fontSize: "8rem", cursor: "pointer" }}
                    onClick={() => {
                        setAlert("Scenario run will start soon", "info");
                        id &&
                            startScenario(id).then((res) => {
                                if (res.error) {
                                    setAlert(res.error, "error");
                                } else {
                                    setAlert("Scenario run started", "info");
                                    getSingleScenario(id);
                                }
                            });
                    }}
                />
                <StopCircleIcon
                    color="primary"
                    sx={{ fontSize: "8rem", cursor: "pointer" }}
                    onClick={() => {
                        id &&
                            stopScenario(id).then((res) => {
                                if (res.error) {
                                    setAlert(res.error, "error");
                                } else {
                                    setAlert("Scenario stopped successfully", "success");
                                }
                            });
                    }}
                />
            </Stack>
        </>
    );
};

export default ScenarioDetail;
