import { ReactElement, createContext, useEffect, useState } from "react";
import * as Paths from "../Paths";
import { Box, CircularProgress } from "@mui/material";
import { UserInfo } from "../pages/Users";
import { UserInfoContext } from "../context/UserInfoContext";
import { Footer } from "../components/Footer";
import Header from "../components/Header";
import { BrowserRouter } from "react-router-dom";

type Props = {
    children: ReactElement<any, any>;
};

export const LoginContext = createContext(() => {});

const redirectToLogin = () => {
    document.location.href = Paths.login.path;
};

const CheckAuth = ({ children }: Props) => {
    const [loggedIn, setLoggedIn] = useState<"logged-in" | "logged-out" | "access-denied">("logged-out");
    const [userInfo, setUserInfo] = useState<null | UserInfo>(null);

    const check = () => {
        const call = async () => {
            try {
                const result = await fetch(Paths.userInfo.path, { redirect: "error" });

                if (result.ok) {
                    const userInfo = await result.json();
                    setLoggedIn("logged-in");
                    setUserInfo(userInfo);
                } else if (result.status === 403) {
                    setLoggedIn("access-denied");
                    setUserInfo(null);
                }
            } catch {
                redirectToLogin();
            }
        };
        call();
    };
    useEffect(check, []);

    if (loggedIn === "logged-out") {
        return (
            <Box width="100vw" height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    } else if (loggedIn === "access-denied") {
        return (
            <BrowserRouter>
                <UserInfoContext.Provider value={null}>
                    <LoginContext.Provider value={redirectToLogin}>
                        <Box height="100vh" width="100vw">
                            <Header />
                            <Box mt={9} mb={8} p={4}>
                                <h1>Access denied</h1>
                                <p>Please contact administrator to get access</p>
                            </Box>
                            <Box bottom="0" width="100vw" position="absolute">
                                <Footer />
                            </Box>
                        </Box>
                    </LoginContext.Provider>
                </UserInfoContext.Provider>
            </BrowserRouter>
        );
    } else {
        return (
            <UserInfoContext.Provider value={userInfo}>
                <LoginContext.Provider value={redirectToLogin}>{children}</LoginContext.Provider>
            </UserInfoContext.Provider>
        );
    }
};

export default CheckAuth;
