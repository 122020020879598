import { Box, Stack, Typography } from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { dollarsToString } from "../utils/numberDisplay";
import { CommunityGivingType } from "../types/dashboard";
import { cardSettings } from "../widgets/constants";
import { CardItem } from "./CardItem";

export const CommunityGiving = ({ totalCommunityGiving, getSelectedStateAndDistrictName }: CommunityGivingType) => {
    return (
        <Box
            p={1}
            minHeight={235}
            sx={{
                borderRadius: cardSettings.smallCardBorderRadius,
                border: cardSettings.border,
                boxShadow: cardSettings.boxShadow,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography variant="h6" component="div" m={1}>
                    Community Impact
                </Typography>
                <HandshakeIcon color="primary" />
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="top" flexWrap="wrap" p={1} ml={1.5}>
                <CardItem
                    styling="33%"
                    value={totalCommunityGiving ? dollarsToString(totalCommunityGiving) : "$ 0"}
                    description={"Invested in community programs"}
                />
            </Stack>
        </Box>
    );
};
