import { Divider, Stack, Typography } from "@mui/material";
import { cardSettings } from "../widgets/constants";
import { ValueAddedType } from "../types/dashboard";
import { ValueAddedLevelChart } from "./ValueAddedLevelChart";
import { BeneficiaryChart } from "./BeneficiaryChart";

type Props = {
    valueAddedChartData: ValueAddedType;
    height: number;
};
export const ValueAdded = ({ valueAddedChartData, height }: Props) => {
    const byValueChainLink = [
        {
            type: "Upstream",
            "Direct suppliers": valueAddedChartData.byValueChainLink.upstream.direct,
            "Suppliers' suppliers": valueAddedChartData.byValueChainLink.upstream.indirect,
            total: valueAddedChartData.byValueChainLink.upstream.total,
        },
        {
            type: "The Coca-Cola system",
            "The Coca-Cola system": valueAddedChartData.system.total,
            total: valueAddedChartData.system.total,
        },
        {
            type: "Downstream",
            "Direct trade outlets": valueAddedChartData.byValueChainLink.downstream.direct,
            "Trade outlets' suppliers": valueAddedChartData.byValueChainLink.downstream.indirect,
            "Consumer sales tax": valueAddedChartData.byValueChainLink.downstream.salesTax,
            total: valueAddedChartData.byValueChainLink.downstream.total,
        },
    ];

    const { salaries, profits, taxes } = valueAddedChartData.byBeneficiary;
    const byBeneficiary = [
        {
            type: "Salaries",
            "The Coca-Cola system": salaries.system,
            "Direct suppliers and trade outlets": salaries.direct,
            "Suppliers' suppliers and trade outlets' suppliers": salaries.indirect,
            total: salaries.total,
        },
        {
            type: "Profits",
            "The Coca-Cola system": profits.system,
            "Direct suppliers and trade outlets": profits.direct,
            "Suppliers' suppliers and trade outlets' suppliers": profits.indirect,
            total: profits.total,
        },
        {
            type: "Taxes",
            "The Coca-Cola system": taxes.system,
            "Direct suppliers and trade outlets": taxes.direct,
            "Suppliers' suppliers and trade outlets' suppliers": taxes.indirect,
            "Consumer sales tax": taxes.salesTax,
            total: taxes.total,
        },
    ];
    return (
        <Stack
            direction={cardSettings.direction}
            justifyContent={cardSettings.justifyContent}
            flexWrap={cardSettings.flexWrap}
            border={cardSettings.border}
            boxShadow={cardSettings.boxShadow}
            borderRadius={cardSettings.borderRadius}
            pt={5}
            pb={5}
            mt={3}
            zIndex={2}
            sx={{ backgroundColor: "white" }}
        >
            <Typography variant="h5" color="primary" fontWeight={600} fontSize="25px" mb={3}>
                Value added
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <ValueAddedLevelChart height={height} byValueChainLink={byValueChainLink} />
                <Divider orientation="vertical" flexItem sx={{ mt: 6 }} />
                <BeneficiaryChart height={height} byBeneficiary={byBeneficiary} />
            </Stack>
        </Stack>
    );
};
