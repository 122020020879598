import { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { getImplanDataSet, ImplanDataSet } from "../api/implanServices";

type ImplanContextType = {
    implanDataSetC: ImplanDataSet | null;
    setImplanDataSet(implanDataSet: ImplanDataSet): void;
    setImplanId(id: string): void;
    getImplanDataset(): void;
    implanId: string;
};

const ImplanContext = createContext<ImplanContextType>({
    implanDataSetC: null,
    setImplanDataSet: (implanDataSet: ImplanDataSet) => {},
    setImplanId: () => {},
    getImplanDataset: () => {},
    implanId: "",
});

export const ImplanProvider = ({ children }: PropsWithChildren) => {
    const [implanDataSetC, setImplanDataSet] = useState<ImplanDataSet | null>(null);
    const [implanId, setImplanId] = useState("");

    const getImplanDataset = useCallback(() => {
        return implanId && getImplanDataSet(implanId).then((res) => setImplanDataSet(res));
    }, [implanId]);
    useEffect(() => {
        getImplanDataset();
    }, [getImplanDataset]);

    return (
        <ImplanContext.Provider
            value={{
                setImplanDataSet,
                implanDataSetC,
                setImplanId,
                getImplanDataset,
                implanId,
            }}
        >
            {children}
        </ImplanContext.Provider>
    );
};

export default ImplanContext;
