import jsPDF from "jspdf";
import { getDate } from "../widgets/getDate";
import html2canvas from "html2canvas";
import logo from "../assets/pdfLogo.png";
import { customFontBold, customFontRegular, customFontMedium, customFontSemiBold } from "../widgets/customFont";
import { defaultNumberFormatter, dollarsToString, millionsToString, thousandsToString } from "../utils/numberDisplay";
import { Results } from "../types/dashboard";
import { calculateValueAddedPercentage } from "../widgets/convertValueAddedPercentage";

export const imageSizes = {
    employment: { width: 280, height: 200 },
    employmentRatio: { width: 90, height: 71 },
    valueAddedByChain: { width: 280, height: 200 },
    communityGiving: { width: 400, height: 190 },
    scale: 2,
};

const settings = {
    marginLeft: 20,
    titleColor: "#F40009",
    mainTitleFontSize: 16,
    grayedText: "#666666",
    titleFontSize: 14,
    chartDescription: 12,
    genericTextFontSize: 10,
    genericTextColor: "#484848",
    pageNumberSize: 7,
    impactsBigFonts: 80,
    chartsTitleFontSize: 10,
    generatedDateTextColor: "#474747",
    headerAndFooterTextColor: "#9c9c9c",
    backgroundColor: {
        r: 255,
        g: 234,
        b: 236,
    },
    circle: {
        r: 255,
        g: 0,
        b: 0,
    },
    paginationPosition: {
        x: 410,
        y: 620,
    },
    reportedDatePosition: {
        x: 130,
        y: 620,
    },
    poweredByPosition: {
        x: 15,
        y: 620,
    },
    logo: {
        marginLeft: 20,
        marginTop: 12,
        w: 120,
        h: 20,
    },
    line: {
        marginLeft: 20,
        marginTop: 38,
        w: 405,
        h: 1,
    },
    lineColor: {
        r: 180,
        g: 180,
        b: 180,
    },
    descriptionBackground: {
        marginLeft: 20,
        marginTop: 65,
        w: 405,
        h: 55,
    },
    impactsBigText: {
        marginLeft: 290,
        marginTop: 238,
        w: 0,
        h: 0,
    },
    map: {
        marginLeft: 85,
        marginTop: 135,
        w: 275,
        h: 170,
    },
};

export async function exportify(
    selectedState: string,
    getDashboardScenarioName: () => any,
    jobs: number | null,
    cocaColaSystemSupports: number,
    result: Results,
    district: any,
) {
    const scenarioName = getDashboardScenarioName();
    const createdDate = getDate();
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Note: January is 0
    const year = currentDate.getFullYear() % 100; // Get last two digits of the year
    // Formatting the date with leading zeros if needed
    const formattedDate = `${day}-${month}-${year}`;
    const header = document.getElementById("header")?.textContent;
    const headerDescription = document.getElementById("headerDescription")?.textContent;
    const mapData = document.getElementById("map");
    const exportEmployment = document.getElementById("exportEmployment");
    const valueAdded = document.getElementById("valueAddedByChainLink");
    const communityGiving = document.getElementById("communityGivingChart");
    const employmentIconsGroup = document.getElementById("iconGroup");

    if (!header) {
        console.error("no header");
        return;
    }

    if (!headerDescription) {
        console.error("no headerDescription");
        return;
    }

    if (!exportEmployment) {
        console.error("no exportEmployment");
        return;
    }

    if (!valueAdded) {
        console.error("no valueAdded");
        return;
    }

    if (!communityGiving) {
        console.error("no communityGiving");
        return;
    }

    if (!mapData) {
        console.error("no mapData");
        return;
    }

    if (!employmentIconsGroup) {
        console.error("no employment");
        return;
    }

    const h2c = async (element: HTMLElement) => {
        const canvas = await html2canvas(element, { scale: 2 });
        return canvas.toDataURL("image/png");
    };

    const employmentChart = await h2c(exportEmployment);
    const valueAddedChart = await h2c(valueAdded);
    const communityGivingChart = await h2c(communityGiving);
    const map = await h2c(mapData);

    const responseForLogo = await fetch(logo);
    const blobLogo = await responseForLogo.blob();
    const logoImg = URL.createObjectURL(blobLogo);

    const jobsPerCocaCola = result.summary.employmentImpact.indirectInducedJobsRatio;

    const isDisplayedAsMillions = cocaColaSystemSupports > 100000;
    const formattedAmount = isDisplayedAsMillions ? millionsToString(cocaColaSystemSupports) : thousandsToString(cocaColaSystemSupports);
    const unit = isDisplayedAsMillions ? "M" : "K";

    const pdf = new jsPDF("p", "px", "a4");
    pdf.addFileToVFS("Inter-Regular-normal.ttf", customFontRegular);
    pdf.addFileToVFS("Inter-Bold-bold.ttf", customFontBold);
    pdf.addFileToVFS("Inter-SemiBold.ttf", customFontSemiBold);
    pdf.addFileToVFS("Inter-Medium.ttf", customFontMedium);
    pdf.addFont("Inter-Bold-bold.ttf", "Inter-Bold", "bold");
    pdf.addFont("Inter-Regular-normal.ttf", "Inter-Regular", "normal");
    pdf.addFont("Inter-Medium.ttf", "Inter-Medium", "medium");
    pdf.addFont("Inter-SemiBold.ttf", "Inter-SemiBold", "semiBold");

    pdf.setFont("Inter-Regular"); // Set the font to your custom font
    pdf.setFillColor(settings.backgroundColor.r, settings.backgroundColor.g, settings.backgroundColor.b);
    pdf.rect(
        settings.descriptionBackground.marginLeft,
        settings.descriptionBackground.marginTop,
        settings.descriptionBackground.w,
        settings.descriptionBackground.h,
        "F",
    );
    pdf.addImage(logoImg, "PNG", settings.logo.marginLeft, settings.logo.marginTop, settings.logo.w, settings.logo.h);
    pdf.setFillColor(settings.lineColor.r, settings.lineColor.g, settings.lineColor.b);
    pdf.rect(settings.line.marginLeft, settings.line.marginTop, settings.line.w, settings.line.h, "F");

    pdf.setTextColor(settings.genericTextColor);
    pdf.setFont("Inter-SemiBold", "semiBold");
    pdf.setFontSize(settings.mainTitleFontSize);
    pdf.text(header, settings.marginLeft, 60);
    pdf.setFont("Inter-Regular", "normal");
    pdf.setFontSize(settings.genericTextFontSize);
    pdf.setTextColor(settings.grayedText);
    const headerDescriptionCropped = pdf.splitTextToSize(headerDescription, 398);
    pdf.text(headerDescriptionCropped, settings.marginLeft + 5, 77);
    pdf.addImage(map, "PNG", settings.map.marginLeft, settings.map.marginTop, settings.map.w, settings.map.h);

    //Value added
    pdf.setFontSize(settings.titleFontSize);
    pdf.setTextColor(settings.titleColor);
    pdf.setFont("Inter-SemiBold", "semiBold");
    pdf.text("Value Added", settings.marginLeft, 340);

    pdf.setTextColor(settings.genericTextColor);
    pdf.setFontSize(settings.genericTextFontSize);
    pdf.setFont("Inter-Regular", "normal");
    pdf.text(
        `The Coca-Cola system supported $${formattedAmount} ${unit} in value added for the GDP of ${
            selectedState === "United States" ? "the United States" : selectedState
        } ${district !== null ? ">" + district.name : ""}`,
        settings.marginLeft,
        360,
    );
    pdf.addImage(valueAddedChart, "PNG", settings.marginLeft - 30, 393, imageSizes.valueAddedByChain.width, imageSizes.valueAddedByChain.height);
    pdf.setFontSize(settings.chartsTitleFontSize);
    pdf.setFont("Inter-Regular", "normal");
    pdf.text("Supported by level in $M", settings.marginLeft + 66, 385);
    pdf.setFont("Inter-Regular", "normal");
    pdf.setFontSize(settings.impactsBigFonts);
    pdf.setTextColor(settings.titleColor);
    pdf.setFont("Inter-Medium", "medium");
    pdf.setFillColor(settings.backgroundColor.r, settings.backgroundColor.g, settings.backgroundColor.b);
    pdf.rect(241, 414, 1, 158, "F");
    pdf.text(calculateValueAddedPercentage(result.summary.economicImpact.gdpSupported) + "%", settings.marginLeft + 258, 515);
    pdf.setTextColor(settings.genericTextColor);
    pdf.setFontSize(settings.genericTextFontSize);
    pdf.setFont("Inter-Regular", "normal");
    const gdp = pdf.splitTextToSize(`${selectedState === "United States" ? "the United States" : selectedState} GDP is supported by the Coca-Cola system`, 165);
    pdf.text(gdp, settings.marginLeft + 241, 550);
    //Generated Text
    pdf.setFontSize(settings.pageNumberSize);
    pdf.setTextColor(settings.titleColor);
    pdf.setFont("Inter-SemiBold", "semiBold");
    pdf.text("Powered by Stewart Redqueen", settings.poweredByPosition.x, settings.poweredByPosition.y);
    pdf.setFont("Inter-Regular", "normal");
    pdf.setTextColor(settings.generatedDateTextColor);
    pdf.setFontSize(settings.pageNumberSize);
    pdf.text(`Report generated on ${createdDate}`, settings.reportedDatePosition.x, settings.reportedDatePosition.y);

    pdf.setFontSize(settings.pageNumberSize);
    pdf.setTextColor(settings.headerAndFooterTextColor);
    pdf.text("1", settings.paginationPosition.x, settings.paginationPosition.y);

    // Page 2
    pdf.addPage();

    pdf.addImage(logoImg, "PNG", settings.logo.marginLeft, settings.logo.marginTop, settings.logo.w, settings.logo.h);
    pdf.setFillColor(settings.lineColor.r, settings.lineColor.g, settings.lineColor.b);
    pdf.rect(settings.line.marginLeft, settings.line.marginTop, settings.line.w, settings.line.h, "F");
    pdf.setFontSize(settings.pageNumberSize);
    pdf.setFont("Inter-Regular", "normal");
    pdf.setTextColor(settings.headerAndFooterTextColor);
    pdf.text("2", settings.paginationPosition.x, settings.paginationPosition.y);
    //Employment
    pdf.setFontSize(settings.titleFontSize);
    pdf.setTextColor(settings.titleColor);
    pdf.setFont("Inter-SemiBold", "semiBold");
    pdf.text("Employment", settings.marginLeft, 60);
    pdf.setTextColor(settings.genericTextColor);
    pdf.setFontSize(settings.genericTextFontSize);
    pdf.setFont("Inter-Regular", "normal");
    pdf.text(
        `The Coca-Cola system supported ${defaultNumberFormatter(jobs)} jobs in ${selectedState === "United States" ? "the United States" : selectedState}${
            district !== null ? ">" + district.name : ""
        } in ${scenarioName ? scenarioName.name : ""} `,
        settings.marginLeft,
        77,
    );
    pdf.addImage(employmentChart, "PNG", settings.marginLeft - 30, 122, imageSizes.employment.width, imageSizes.employment.height);
    pdf.setFont("Inter-SemiBold", "semiBold");
    pdf.setFontSize(settings.chartDescription);
    pdf.setFont("Inter-Regular", "normal");
    pdf.setFontSize(settings.chartsTitleFontSize);
    pdf.text(`Supported by level in # people`, settings.marginLeft + 56, 104);
    const employeeText = pdf.splitTextToSize(
        `For every direct job in the Coca-Cola system, ${jobsPerCocaCola ? jobsPerCocaCola?.toFixed(0) : 0} indirect and induced jobs are supported `,
        165,
    );
    pdf.setFontSize(settings.genericTextFontSize);
    pdf.setFont("Inter-Regular", "normal");
    pdf.text(employeeText, settings.marginLeft + 227, 272);

    pdf.setFillColor(settings.backgroundColor.r, settings.backgroundColor.g, settings.backgroundColor.b);
    pdf.rect(235, 125, 1, 182, "F");
    pdf.setFontSize(settings.impactsBigFonts);
    pdf.setFont("Inter-Medium", "medium");
    pdf.setTextColor(settings.titleColor);
    pdf.text(`1:${jobsPerCocaCola ? jobsPerCocaCola?.toFixed(0) : 0}`, settings.impactsBigText.marginLeft, settings.impactsBigText.marginTop);
    pdf.setFont("Inter-Regular", "normal");
    // Community Giving
    pdf.setFontSize(settings.titleFontSize);
    pdf.setTextColor(settings.titleColor);
    pdf.setFont("Inter-SemiBold", "semiBold");
    pdf.text("Community", settings.marginLeft, 340);
    pdf.addImage(communityGivingChart, "PNG", settings.marginLeft + 10, 405, imageSizes.communityGiving.width, imageSizes.communityGiving.height);
    pdf.setTextColor(settings.genericTextColor);
    pdf.setFont("Inter-Regular", "normal");
    pdf.setFontSize(settings.chartsTitleFontSize);
    pdf.text("By focus area in % and $", settings.marginLeft + 166, 396);
    pdf.setFontSize(settings.genericTextFontSize);
    const communityGivingText = pdf.splitTextToSize(
        `The Coca-Cola Company, bottling partners, The Coca-Cola Foundation, and the Coca-Cola Scholars Foundation contributed ${
            result?.summary.communityGiving.totalCommunityGiving ? dollarsToString(result?.summary.communityGiving.totalCommunityGiving) : "0"
        } in community programs.`,
        420,
    );
    pdf.text(communityGivingText, settings.marginLeft, 364);

    pdf.setFontSize(settings.pageNumberSize);
    pdf.setTextColor(settings.titleColor);
    pdf.setFont("Inter-SemiBold", "semiBold");
    pdf.text("Powered by Stewart Redqueen", settings.poweredByPosition.x, settings.poweredByPosition.y);
    pdf.setTextColor(settings.generatedDateTextColor);
    pdf.setFont("Inter-Regular", "normal");
    pdf.setFontSize(settings.pageNumberSize);
    pdf.text(`Report generated on ${createdDate}`, settings.reportedDatePosition.x, settings.reportedDatePosition.y);
    pdf.save(`EI CC USA_${selectedState}_${formattedDate}.pdf`);
}
