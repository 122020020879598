import { Box, Typography } from "@mui/material";
import { BarChart } from "../charts/BarChart";
import { chartTitleStyling, getChartBoxStyling } from "../charts/styling";
import { valueAdded2Colors } from "../charts/colors";
import { millionsToString } from "../utils/numberDisplay";
type Props = {
    height: number;
    byBeneficiary: any;
};
export const BeneficiaryChart = ({ height, byBeneficiary }: Props) => {
    return (
        <Box sx={getChartBoxStyling(height)}>
            <Typography component="span" sx={chartTitleStyling}>
                Value added supported by beneficiary <span>(USD million)</span>
            </Typography>
            <BarChart
                data={byBeneficiary}
                indexBy="type"
                keys={["The Coca-Cola system", "Direct suppliers and trade outlets", "Suppliers' suppliers and trade outlets' suppliers", "Consumer sales tax"]}
                colors={valueAdded2Colors}
                title="Value added supported by beneficiary"
                boldSpan="(USD million)"
                isSystemCleared={false}
                numberFormatter={millionsToString}
                legendTotalInOneColumn={3}
            />
        </Box>
    );
};
