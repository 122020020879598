import { PropsWithChildren, createContext, useEffect } from "react";
import { useBottler, useUsers } from "../api/userServices";
export type Bottler = { id: number; name: string };
export type User = {
    id: number;
    name: string;
    email: string;
    disabled: boolean;
    userRole: "Admin" | "CocaColaCompany" | "Bottler" | string;
    bottlerName?: string;
    bottlerId?: number;
};
export type UserContextType = {
    bottlerList: Array<Bottler>;
    setBottlerList: (bottler: Array<Bottler>) => void;
    userList: Array<User>;
};
export const UserContext = createContext<UserContextType | null>(null) as React.Context<UserContextType>;

const UserProvider = ({ children }: PropsWithChildren) => {
    const [userList, fetchUsers] = useUsers();
    const [bottlerList, setBottlerList, fetchBottlers] = useBottler();

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        fetchBottlers();
    }, [fetchBottlers]);
    return (
        <UserContext.Provider
            value={{
                bottlerList,
                setBottlerList,
                userList,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
export default UserProvider;
