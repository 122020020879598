import { Box, Checkbox, Divider, FormControlLabel, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { memo, useCallback, useEffect, useState } from "react";
import { updateScenarioScopes } from "../../api/scenarioServices";
import { useParams } from "react-router-dom";
import useAlert from "../../hooks/useAlert";
import { State } from "../../pages/ScenarioDetail";

type Props = { states: Array<State>; setStates: (states: Array<State>) => void; editable: boolean };

const ScenarioStates = ({ states, setStates, editable }: Props) => {
    const [searchState, setSearchState] = useState("");
    const [filteredStates, setFilteredStates] = useState<Array<State> | []>([]);

    const { setAlert } = useAlert();
    const { id } = useParams();
    const scenarioId = id;

    useEffect(() => {
        if (searchState.length > 1 && states) {
            setFilteredStates(states.filter((i) => i.name.toLowerCase().includes(searchState)));
        } else if (searchState === "") {
            setFilteredStates(states);
        }
    }, [states, searchState]);

    const handleScenarioStateChange = useCallback(
        (id: number) => {
            const updatedStates = states.map((state) => {
                if (state.id === id) {
                    scenarioId &&
                        updateScenarioScopes(scenarioId, "state", id, !state.checked)
                            .then((res) => {
                                if (res.error) {
                                    setAlert(res.error, "error");
                                    return state;
                                }
                            })
                            .catch((err) => console.log(err));
                    return { ...state, checked: !state.checked };
                } else {
                    return state;
                }
            });
            setStates(updatedStates);
        },
        [setStates, states, scenarioId, setAlert]
    );

    return (
        <Box width="32%" ml="1rem" overflow="auto">
            <Typography sx={{ textDecoration: "underline" }}>State</Typography>
            <TextField
                disabled={!editable}
                sx={{ mt: 1, mb: 1, width: "90%" }}
                size="small"
                label="Search State"
                variant="outlined"
                value={searchState}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => setSearchState(e.target.value)}
            />
            {filteredStates.map((state) => {
                return (
                    <Stack key={state.id} direction="row" justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                            label={state.name}
                            control={<Checkbox disabled={!editable} checked={state.checked} onChange={() => handleScenarioStateChange(state.id)} />}
                        />
                        <Divider />
                    </Stack>
                );
            })}
        </Box>
    );
};

export default memo(ScenarioStates);
