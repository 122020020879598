import { Button, Dialog, DialogActions, DialogTitle, } from "@mui/material"

type Props = {
    handleClose: () => void,
    openDialog: boolean,
    questionText: string,
    confirmationAction: (cb: any) => void,
    confirmationText: string

}
export const ConfirmationDialog = (props: Props) => {
    const { handleClose, openDialog, questionText, confirmationAction, confirmationText } = props;

    return <Dialog onClose={handleClose} open={openDialog} fullWidth>
        <DialogTitle>{questionText}</DialogTitle>

        <DialogActions>
            <Button onClick={handleClose}>
                Cancel
            </Button>
            <Button onClick={confirmationAction}>
                {confirmationText}
            </Button>
        </DialogActions>
    </Dialog>
}
